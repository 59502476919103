import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Input,
  Text,
} from "@chakra-ui/react";
import { Modal, Table, DatePicker, Select } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useToastWrapper } from "../../Wrapper/toastWrapper";
import { AdminVisitor } from "../../Interface/AdminInterfaces";
import { AdminAPI } from "../../Apis/adminAPI";
import Loader from "../../Components/Loader/Loader";
import UsersCsv from "../../Assets/visitor_bulk.csv";
import Pagination from "../../Components/Pagination/Pagination";
import { validateEmail } from "../../Utils/Validator";
import { errorHandler } from "../../Utils/handler";
import BulkUploadConfirmModal from "../../Components/Modals/BulkUploadConfirmModal";
import { useNavigate } from "react-router-dom";

type Props = {};

const mandatoryFields = [
  "eventId",
  "visitorCompanyName",
  "visitorPersonName",
  "visitorOfficialEmail",
];

const Visitor = (props: Props) => {
  const PAGESIZE = 100;
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(false);

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState<any>([]);

  // Add Visitors Modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isBulkConfirmModalVisible, setIsBulkConfirmModalVisible] =
    useState(false);

  const showModal = () => setIsModalVisible(true);

  const handleOk = () => setIsModalVisible(false);

  // Bulk Upload Visitors Modal
  const [isBulkModalVisible, setIsBulkModalVisible] = useState(false);

  const showBulkModal = () => {
    setBulkDocument("");
    setBulkEventId("");
    setFileInputKey(Date.now());
    setIsBulkModalVisible(true);
  };

  const showBulkConfirmModal = () => setIsBulkConfirmModalVisible(true);

  const hideBulkConfirmModal = () => setIsBulkConfirmModalVisible(false);

  const handleBulkOk = () => setIsBulkModalVisible(false);
  const [bulkEventId, setBulkEventId] = useState("");
  const [bulkDocument, setBulkDocument] = useState<any>("");
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const [eventName, setEventName] = useState("");
  //Delete
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const showDeleteModal = () => setIsDeleteModalOpen(true);

  const handleDeleteModalOk = () => setIsDeleteModalOpen(false);

  const [deleteid, setDeleteId] = useState("");

  const [formData, setFormData] = useState<AdminVisitor>({
    eventId: "",
    visitorCompanyName: "",
    visitorPersonName: "",
    visitorPersonCity: "",
    visitorPersonState: "",
    visitorPersonCountry: "",
    visitorOfficialEmail: "",
    visitorSocialEmail: "",
    visitorPhoneNumber: "",
    visitorDesignation: "",
  });

  const [edit, setEdit] = useState(false);
  const [editId, setEditId] = useState("");

  const [data, setData] = useState<any>([]);
  const [eventData, setEventData] = useState<any>([]);
  const [sortEventId, setSortEventId] = useState("");

  const handleDeleteOk = async (selectedRows: []) => {
    setLoading(true);

    const apiPromises: any[] = [];

    try {
      const arrResponse = await selectedRows.map(
        async (item: any, index: Number) => {
          const response = await handleDelete(item._id);
          apiPromises.push(response);
        }
      );

      Promise.all(apiPromises)
        .then((results) => {
          success("Visitors deleted successfully", "");
          getVisitorsData();
        })
        .catch((err) => {
          error("Visitors delete failed");
        });
    } catch (err) {
      // console.log(err);
      error(errorHandler(err));
    } finally {
      setSelectedRowKeys([]);
      setSelectedRows([]);
      setLoading(false);
    }
  };

  const resetPasswordClick = async (selectedRows: []) => {
    setLoading(true);

    const apiPromises: any[] = [];

    try {
      if (selectedRows.length == 0) {
        error("Please select atleast one entry to reset !");
      } else {
        selectedRows.map(async (item: any, index: Number) => {
          console.log(index);
          const response = await handleResetPassword(item.userId);
          apiPromises.push(response);
        });
        Promise.all(apiPromises)
          .then((results) => {
            success("Password reset successfully", "");
          })
          .catch((err) => {
            error("Password reset failed");
          });
      }
    } catch (err) {
      // console.log(err);
      error(errorHandler(err));
    } finally {
      setLoading(false);
      setSelectedRowKeys([]);
      setSelectedRows([]);
    }
  };

  const handleResetPassword = async (userId: string) => {
    // setLoading(true);
    try {
      const res = await AdminAPI.resetPassword(userId);
      // if (res) success("Password reset successfully", "");
      return res;
    } catch (err) {
      // console.log(err);
      // error(errorHandler(err));
      throw err;
    } finally {
      // setLoading(false);
    }
  };

  const clearData = () => {
    setFormData({
      eventId: "",
      visitorCompanyName: "",
      visitorPersonName: "",
      visitorPersonCity: "",
      visitorPersonState: "",
      visitorPersonCountry: "",
      visitorOfficialEmail: "",
      visitorSocialEmail: "",
      visitorPhoneNumber: "",
      visitorDesignation: "",
    });
  };

  const handleDelete = async (key: any) => {
    // setLoading(true);
    try {
      const res = await AdminAPI.deleteVisitor(key);
      // if (res) success("Visitor deleted successfully", "");
      // getVisitorsData();
      return res;
    } catch (err) {
      // error(errorHandler(err));
      throw err;
    } finally {
      // setLoading(false);
    }
  };

  const columns = [
    {
      title: "Event Name",
      dataIndex: "eventName",
      key: "eventName",
    },
    {
      title: "Company",
      dataIndex: "visitorCompanyName",
      key: "visitorCompanyName",
      sorter: (a: any, b: any) =>
        a.visitorCompanyName.localeCompare(b.visitorCompanyName),
    },
    {
      title: "Name",
      dataIndex: "visitorPersonName",
      key: "visitorPersonName",
      sorter: (a: any, b: any) =>
        a.visitorPersonName.localeCompare(b.visitorPersonName),
    },
    {
      title: "Official Email",
      dataIndex: "visitorOfficialEmail",
      key: "visitorOfficialEmail",
    },
    {
      title: "Phone",
      dataIndex: "visitorPhoneNumber",
      key: "visitorPhoneNumber",
    },
    {
      title: "Designation",
      dataIndex: "visitorDesignation",
      key: "visitorDesignation",
    },
    {
      title: "Social Email",
      dataIndex: "visitorSocialEmail",
      key: "visitorSocialEmail",
    },
    {
      title: "City",
      dataIndex: "visitorPersonCity",
      key: "visitorPersonCity",
    },
    {
      title: "State",
      dataIndex: "visitorPersonState",
      key: "visitorPersonState",
    },
    {
      title: "Country",
      dataIndex: "visitorPersonCountry",
      key: "visitorPersonCountry",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_: any, object: any) => (
        <>
          <Flex gap={1}>
            <Button
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              h={"100%"}
              background={"primary.main"}
              color={"white"}
              px={3}
              py={1}
              onClick={() => {
                setFormData({
                  eventId: object.eventId,
                  visitorCompanyName: object.visitorCompanyName,
                  visitorPersonName: object.visitorPersonName,
                  visitorPersonCity: object.visitorPersonCity,
                  visitorPersonState: object.visitorPersonState,
                  visitorPersonCountry: object.visitorPersonCountry,
                  visitorOfficialEmail: object.visitorOfficialEmail,
                  visitorSocialEmail: object.visitorSocialEmail,
                  visitorPhoneNumber: object.visitorPhoneNumber,
                  visitorDesignation: object.visitorDesignation,
                });
                setEdit(true);
                setEditId(object.visitorId);
                showModal();
              }}
            >
              Edit
            </Button>
            <Button
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              h={"100%"}
              background={"primary.main"}
              color={"white"}
              px={3}
              py={1}
              onClick={() => {
                navigate('/admin/visitor-rewards',{
                  state: { visitorId: object.visitorId },
                })
              }}
            >
              Rewards
            </Button>
            {/* <Button
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              h={"100%"}
              background={"button.green"}
              color={"white"}
              px={3}
              py={1}
              onClick={() => {
                handleResetPassword(object.userId);
              }}
            >
              Reset Password
            </Button>
            <Button
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              h={"100%"}
              background={"secondary.light"}
              color={"secondary.main"}
              px={3}
              py={1}
              onClick={() => {
                showDeleteModal();
                setDeleteId(object.visitorId);
              }}
            >
              Delete
            </Button> */}
          </Flex>
        </>
      ),
    },
  ];

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const { error, success } = useToastWrapper();

  const validate = () => {
    console.log(
      mandatoryFields.map((field) => formData[field as keyof AdminVisitor])
    );
    if (
      mandatoryFields.some((field) => !formData[field as keyof AdminVisitor])
    ) {
      error("Please fill all the mandatory fields", "");
      return false;
    }

    if (!validateEmail(formData.visitorOfficialEmail)) {
      error("Please enter valid email", "");
      return false;
    }
    if (
      formData.visitorSocialEmail &&
      !validateEmail(formData.visitorSocialEmail)
    ) {
      error("Please enter valid email", "");
      return false;
    }

    return true;
  };

  const getData = async () => {
    setLoading(true);
    try {
      const [data1] = await Promise.all([AdminAPI.getEvent()]);
      if (data1 && data1.data?.data?.length > 0) {
        setEventData(
          data1.data.data.map((event: any) => ({
            label: event.eventName,
            value: event._id,
          }))
        );
      }
    } catch (err) {
      error(errorHandler(err));
    } finally {
      setLoading(false);
    }
  };

  const getVisitorsData = async () => {
    setLoading(true);
    try {
      const res = await AdminAPI.getEventVisitor({
        eventId: sortEventId,
        page: currentPage,
        limit: PAGESIZE,
      });
      if (res) {
        console.log("being called getdata..", res.data);
        setData(res.data.data);
        setTotal(res.data.totalCount);
      }
    } catch (err) {
      error(errorHandler(err));
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    setLoading(true);
    if (edit) {
      try {
        const res = await AdminAPI.putVisitor(editId, formData);
        success("Visitor edited successfully", "");
        clearData();
        getVisitorsData();
        handleOk();
        if (res) {
        }
      } catch (err) {
        console.log(err);
        error(errorHandler(err));
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const res = await AdminAPI.postVisitor(formData);
        success("Visitor added successfully", "");
        clearData();
        getVisitorsData();
        handleOk();
        if (res) {
        }
      } catch (err) {
        console.log(err);
        error(errorHandler(err));
      } finally {
        setLoading(false);
      }
    }
  };

  const handleBulkUpload = async () => {
    console.log("Bulk Upload");
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("file", bulkDocument);
      const res = await AdminAPI.bulkUploadUsers(formData, {
        role: "Visitor",
        eventId: bulkEventId,
      });
      if (res) {
        success("Visitors added successfully", "");
        clearData();
        getVisitorsData();
        handleBulkOk();
        hideBulkConfirmModal();
      }
    } catch (err) {
      console.log(err);
      error(errorHandler(err));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getVisitorsData();
  }, [sortEventId, currentPage]);

  // Function to handle row selection
  const onSelectChange = (selectedRowKeys: any, selectedRows: any) => {
    // console.log("Selected Row Keys:", selectedRows);
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const generateRowKey = (record: any) => record._id;

  return (
    <div className="p-4">
      {loading ? <Loader /> : null}

      {/* Delete */}
      <Modal
        title="Alert"
        footer={null}
        onCancel={handleDeleteModalOk}
        open={isDeleteModalOpen}
      >
        <Text my={5} mt={8}>
          {/* Deleting a Visitor will permanatly remove that Visitor. Are you sure ? */}
          Deleting visitors is permanent. Are you sure ?
        </Text>
        <Flex justifyContent={"space-between"}>
          <Button
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            h={"100%"}
            background={"primary.main"}
            color={"white"}
            px={3}
            py={1}
            onClick={() => {
              handleDeleteModalOk();
            }}
          >
            No
          </Button>
          <Button
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            h={"100%"}
            background={"secondary.light"}
            color={"secondary.main"}
            px={3}
            py={1}
            onClick={() => {
              // handleDelete(deleteid);
              handleDeleteOk(selectedRows);
              handleDeleteModalOk();
            }}
          >
            Yes
          </Button>
        </Flex>
      </Modal>

      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={isBulkConfirmModalVisible}
        onOk={hideBulkConfirmModal}
        onCancel={hideBulkConfirmModal}
      >
        <BulkUploadConfirmModal
          role={"visitor"}
          eventName={eventName}
          fileName={bulkDocument.name}
          handleCloseModal={hideBulkConfirmModal}
          handleSubmit={handleBulkUpload}
        />
      </Modal>

      {/* Bulk Upload Visitors */}
      <Modal
        footer={null}
        title="Bulk Add Visitor"
        width={"40%"}
        open={isBulkModalVisible}
        onCancel={handleBulkOk}
        onOk={handleBulkOk}
      >
        <Box p={2}>
          <Flex gap={2} alignItems={"center"}>
            <Text>Download Sample CSV File</Text>
            <a href={UsersCsv} download={true}>
              <Button
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                h={"100%"}
                background={"primary.main"}
                color={"white"}
                px={3}
                gap={2}
                py={2}
              >
                Download
              </Button>
            </a>
          </Flex>
          <Grid mt={3} gap={3} templateColumns="repeat(1, 1fr)">
            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Event
                </Text>
                <Select
                  placeholder="Select Event"
                  style={{
                    height: "40px",
                  }}
                  options={eventData}
                  onChange={(value, option: any) => {
                    console.log(value);
                    setBulkEventId(value);
                    setEventName(option.label);
                  }}
                  value={bulkEventId}
                />
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Event
                </Text>
                <Input
                  type="file"
                  height="40px"
                  placeholder="Select File"
                  name="visitorPersonName"
                  // value={bulkDocument}
                  key={fileInputKey}
                  onChange={(e) => {
                    console.log(e.target.files);
                    if (e.target.files && e.target.files[0]) {
                      setBulkDocument(e.target.files[0]);
                    }
                  }}
                />
              </Box>
            </GridItem>
          </Grid>
          <Box mt={10}>
            {bulkDocument && bulkEventId ? (
              <Flex justifyContent={"end"}>
                <Button
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  h={"40px"}
                  background={"primary.main"}
                  color={"white"}
                  px={3}
                  gap={2}
                  onClick={showBulkConfirmModal}
                >
                  Submit
                </Button>
              </Flex>
            ) : null}
          </Box>
        </Box>
      </Modal>

      {/* Add Visitors Modal */}
      <Modal
        footer={null}
        title={edit ? "Edit Visitor" : "Add Visitor"}
        width={"80%"}
        open={isModalVisible}
        onCancel={handleOk}
        onOk={handleOk}
      >
        <Box padding={2}>
          <Grid gap={4} templateColumns="repeat(3, 1fr)">
            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Event Name <span style={{ color: "red" }}>*</span>
                </Text>
                <Select
                  placeholder="Select Event"
                  style={{
                    height: "40px",
                  }}
                  options={eventData}
                  value={formData.eventId}
                  onChange={(value) => {
                    console.log(value);

                    setFormData({
                      ...formData,
                      eventId: value,
                    });
                  }}
                />
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Company Name <span style={{ color: "red" }}>*</span>
                </Text>
                <Input
                  height="40px"
                  placeholder="Enter Company Name"
                  name="visitorCompanyName"
                  value={formData.visitorCompanyName}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>

            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Person Name <span style={{ color: "red" }}>*</span>
                </Text>
                <Input
                  height="40px"
                  placeholder="Enter Person Name"
                  name="visitorPersonName"
                  value={formData.visitorPersonName}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>

            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Official Email <span style={{ color: "red" }}>*</span>
                </Text>
                <Input
                  height="40px"
                  placeholder="Enter Official Email"
                  name="visitorOfficialEmail"
                  value={formData.visitorOfficialEmail}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>

            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Phone
                </Text>
                <Input
                  height="40px"
                  placeholder="Enter Phone"
                  name="visitorPhoneNumber"
                  value={formData.visitorPhoneNumber}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>

            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Designation
                </Text>
                <Input
                  height="40px"
                  placeholder="Enter Designation"
                  name="visitorDesignation"
                  value={formData.visitorDesignation}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>

            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Social Email
                </Text>
                <Input
                  height="40px"
                  placeholder="Enter Social Email"
                  name="visitorSocialEmail"
                  value={formData.visitorSocialEmail}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>

            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  City
                </Text>
                <Input
                  height="40px"
                  placeholder="Enter City"
                  name="visitorPersonCity"
                  value={formData.visitorPersonCity}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>

            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  State
                </Text>
                <Input
                  height="40px"
                  placeholder="Enter State"
                  name="visitorPersonState"
                  value={formData.visitorPersonState}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>

            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Country
                </Text>
                <Input
                  height="40px"
                  placeholder="Enter Country"
                  name="visitorPersonCountry"
                  value={formData.visitorPersonCountry}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>
          </Grid>

          <Box mt={10}>
            <Flex justifyContent={"end"}>
              <Button
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                h={"40px"}
                background={"primary.main"}
                color={"white"}
                px={3}
                gap={2}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Flex>
          </Box>
        </Box>
      </Modal>
      <Flex justifyContent={"space-between"} mt={"10px"}>
        <Text size={"lg"} fontWeight={600}>
          Visitors
        </Text>
        <Flex gap={3}>
          <Input
            placeholder="Search by name"
            type="text"
            border={"1px solid"}
            outline={"none"}
            _focus={{ outline: "none", border: "1px solid" }}
            h={"35px"}
            w={"200px"}
            px={2}
            borderColor={"gray.300"}
          />
          <Button
            display={"flex"}
            onClick={() => {
              setEdit(false);
              clearData();
              setEditId("");
              showModal();
            }}
            justifyContent={"center"}
            alignItems={"center"}
            h={"100%"}
            background={"primary.main"}
            color={"white"}
            px={3}
            gap={2}
          >
            <AiOutlinePlus /> Visitor
          </Button>
          <Button
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            h={"100%"}
            background={"primary.main"}
            color={"white"}
            px={3}
            onClick={showBulkModal}
          >
            Import
          </Button>
        </Flex>
      </Flex>

      <Box
        my={"50px"}
        width={"30%"}
        display={"flex"}
        flexDirection={"column"}
        gap={"10px"}
      >
        <Text fontSize={"sm"} fontWeight={"500"}>
          Select Event
        </Text>
        <Select
          style={{
            height: "40px",
          }}
          placeholder="Select Event"
          onChange={(value) => {
            setSortEventId(value);
          }}
          value={sortEventId}
          options={[
            {
              label: "All",
              value: "",
            },
            ...eventData,
          ]}
        />
      </Box>

      <Box>
        {total === 0 ? (
          <Text>No Visitors Found</Text>
        ) : (
          <Text>Total Visitors: {total}</Text>
        )}
      </Box>

      <Box mt={5}>
        <Flex justifyContent={"start"} mb={1} gap={1}>
          <Button
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            h={"100%"}
            background={"button.green"}
            color={"white"}
            px={3}
            py={1}
            onClick={() => {
              resetPasswordClick(selectedRows);
            }}
          >
            Reset Password
          </Button>
          <Button
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            h={"100%"}
            background={"secondary.light"}
            color={"secondary.main"}
            px={3}
            py={1}
            onClick={() => {
              if (selectedRows.length > 0) {
                showDeleteModal();
              } else {
                error("Please select atleast one entry to delete !");
              }

              // setDeleteId(object.exhibitorId);
            }}
          >
            Delete
          </Button>
        </Flex>
        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          dataSource={data}
          bordered
          columns={columns}
          pagination={false}
          rowKey={generateRowKey}
        />
        <Flex justifyContent={"end"}>
          <Pagination
            current={currentPage}
            total={total}
            onChange={(number) => setCurrentPage(number)}
            pageSize={PAGESIZE}
          />
        </Flex>
      </Box>
    </div>
  );
};

export default Visitor;
