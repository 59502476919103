import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Input,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";

import { Collapse, Modal, Select } from "antd";

//// Icons
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { IoMdNotificationsOutline } from "react-icons/io";
import { FaCaretDown, FaCaretUp, FaRegCalendarAlt } from "react-icons/fa";
import { FaChrome } from "react-icons/fa6";

import { TfiWorld } from "react-icons/tfi";
import { BiFilter, BiSearch, BiSort } from "react-icons/bi";
import { useNavigate, useSearchParams } from "react-router-dom";
import SortModal from "../../Components/Modals/SortModal";
import { errorHandler } from "../../Utils/handler";
import { useToastWrapper } from "../../Wrapper/toastWrapper";
import { ExhibitorAPI } from "../../Apis/exhibitorAPI";
import { EventInterface } from "../../Interface/CommonInterface";
import Loader from "../../Components/Loader/Loader";
import {
  MEETING_REQUEST_STATUS,
  RelevanceFieldsNew,
  VisitorScreens,
  relevanceFields,
} from "../../Constants/data";
import FilterModal from "../../Components/Modals/FilterModal";
import { get } from "http";
import moment from "moment";
import { VisitorAPI } from "../../Apis/visitorAPI";
import RelevanceModalVisitor from "../../Components/Modals/RelevanceModalVisitor";
import { FiEdit } from "react-icons/fi";
import NotesModal from "../../Components/Modals/NotesModal";
import { Icons } from "../../Assets/icons";
import { IoChatboxEllipsesOutline, IoClose } from "react-icons/io5";
import CreateMeetModal from "../../Components/Modals/CreateMeetModal";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../redux/Actions";
import FilterToggleButton from "../../Components/Common/FilterToggleButton";
import { MdCalendarToday, MdChat, MdClose } from "react-icons/md";
import { CommonAPI } from "../../Apis/commonApi";
import ConfirmationModal from "../../Components/Modals/ConfirmationModal";
import ViewDocsModal from "../../Components/Modals/ViewDocsModal";

interface inputType {
  event: string;
  company: string;
}

const VisitorHome = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const exhibitorAsset = useSelector((state: any) => state.exhibitorAsset);

  const filterToggleRef = React.useRef<HTMLButtonElement>();

  const isInitialRender = useRef(true);

  const user = useSelector((state: any) => state.user);

  const [relevanceData, setRelevanceData] = useState({
    prequalified_company_name: "",
    prequalified_user_id: "",
    event_id: "",
    status: "Under_Research",
  });

  const [isTagsFilterActive, setIsTagsFilterActive] = useState(false);

  // Sort Modal
  const [docsModal, setDocsModal] = useState(false);
  const handleOpenDocsModal = () => setDocsModal(true);
  const handleCloseDocsModal = () => setDocsModal(false);
  const [personName, setPersonName] = useState("");
  const [currentExhibitor, setCurrentExhibitor] = useState<any>({});

  // Sort Modal
  const [sortModal, setSortModal] = useState(false);
  const handleOpenSortModal = () => setSortModal(true);
  const handleCloseSortModal = () => setSortModal(false);

  // Filter Modal
  const [filterModal, setFilterModal] = useState(false);
  const handleOpenFilterModal = () => setFilterModal(true);
  const handleCloseFilterModal = () => setFilterModal(false);

  // Tags filter Modal
  const [tagsFilter, setTagsFilter] = useState(false);
  const handleOpenTagsFilterModal = () => setTagsFilter(true);
  const handleCloseTagsFilterModal = () => setTagsFilter(false);

  const [interestedTags, setInterestedTags] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  // Relevance Modal
  const [releModel, setReleModel] = useState(false);
  const handleOpenRelevanceModel = () => setReleModel(true);
  const handleCloseRelevanceModel = () => setReleModel(false);

  //Sort Consts
  const [orderSort, setOrderSort] = useState("a-to-z");
  const [relevanceSort, setRelevanceSort] = useState("");

  //Notes Modal
  const [notesModal, setNotesModal] = useState(false);
  const handleOpenNotesModal = () => setNotesModal(true);
  const handleCloseNotesModal = () => setNotesModal(false);
  const [notesData, setNotesData] = useState<any>({
    forUser: "",
    eventId: "",
  });

  //Meet Modal
  const [meetModal, setMeetModal] = useState(false);
  const handleOpenMeetModal = () => setMeetModal(true);
  const handleCloseMeetModal = () => setMeetModal(false);
  const [meetResponder, setMeetResponder] = useState<any>();

  const [accordinActive, setAccordinActive] = useState("");

  //Notes Modal
  const [tagsModel, setTagsModal] = useState(false);
  const handleOpenTagsModal = () => setTagsModal(true);
  const handleCloseTagsModal = () => setTagsModal(false);
  const [tagsData, setTagsData] = useState<string[]>([]);

  //Meet confirmation modal
  const [meetConfirm, setMeetConfirm] = useState(false);
  const handleOpenMeetConfirmModal = () => setMeetConfirm(true);
  const handleCloseMeetConfirmModal = () => {
    setMeetConfirm(false);
    setCurrentMeet("");
  };

  const [currentMeet, setCurrentMeet] = useState("");

  const [search, setSearch] = useState("");

  const [resetSearch, setResetSearch] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);

  const [inputs, setInputs] = useState({
    event: "",
    company: "",
  });

  const [event, setEvent] = useState<EventInterface[]>([]);

  const [companies, setCompanies] = useState<any>([]);

  const [visitorData, setVisitorData] = useState<any>({});

  const [meetingData, setMeetingData] = useState<any>([]);

  const { success, error } = useToastWrapper();

  const resetFilters = () => {
    if (search !== "") {
      setResetSearch(!resetSearch);
    }
    setSearch("");
    setOrderSort("a-to-z");
    if (orderSort === "z-to-a") {
      filterToggleRef.current?.click();
    }
    setRelevanceSort("");
    setSelectedTags([]);
  };

  const getEvents = async () => {
    try {
      setLoading(true);
      const [data, data2, data3] = await Promise.all([
        VisitorAPI.getEvents(),
        VisitorAPI.getVisitorProfile(),
        CommonAPI.GetMeeting({
          status: undefined,
        }),
      ]);

      if (data?.success) {
        const arr2 = [...data.data.data].filter((s) =>
          moment(s.startDate).isBefore(moment())
        );
        const arr = [...arr2];
        setEvent(arr.reverse());
        setInputs({
          ...inputs,
          event: arr2[arr2.length - 1]?._id || "",
        });
      }
      if (data2) setVisitorData(data2?.data?.data);

      if (data3) {
        const response = JSON.parse(JSON.stringify(data3.data.data));
        let meetingDataObj: any = {};
        response.forEach((element: any) => {
          let temp = {
            _id: element._id,
            requesterId: element.requesterId,
            responderId: element.responderId,
            requesterStatus: element.requesterStatus,
            responderStatus: element.responderStatus,
          };

          let key =
            user.user === element.requesterId
              ? element.responderCompany
              : element.requesterCompany;

          meetingDataObj[key] = temp;
        });

        setMeetingData(meetingDataObj);
      }
    } catch (err: any) {
      const errMsg = errorHandler(err);

      error(errMsg);
    } finally {
      // setLoading(false);
    }
  };

  const getInterestedTags = async () => {
    try {
      setLoading(true);
      const params: any = {};

      params["eventId"] = inputs?.event;
      const data = await VisitorAPI.getInterestedTags(params);

      if (data.success) {
        console.log(data.data);
        setInterestedTags(data.data.data);
      }
    } catch (err: any) {
      const errMsg = errorHandler(err);
      error(errMsg);
    } finally {
      // setLoading(false);
    }
  };

  const getExhibitorCompanies = async (
    eventId: string,
    o: string = orderSort
  ) => {
    try {
      setLoading(true);

      const params: any = {};

      if (eventId) {
        params["eventId"] = eventId;
      }

      if (search) {
        params["search"] = search;
      }

      if (o) {
        params["filter"] = o;
      }

      if (relevanceSort) {
        params["categoryToFilter"] = relevanceSort;
      }
      if (selectedTags.length > 0) {
        params["tags"] = selectedTags;
      }

      console.log("im inside here...");
      console.log("params...", params);

      const data = await VisitorAPI.getExhibitors(params);

      if (data.success) {
        // console.log(data.data);
        // let updatedData = data?.data?.data.map((item: any) => {
        //   item.links = [
        //     {
        //       id: 1,
        //       url: "https://www.google.com",
        //       title: "Title 1",
        //     },
        //     {
        //       id: 2,
        //       url: "https://www.google.com",
        //       title: "Title 2",
        //     },
        //     {
        //       id: 3,
        //       url: "https://www.google.com",
        //       title: "Title 3",
        //     },
        //   ];

        //   return item;
        // });
        // console.log("updatedData...", updatedData);
        console.log("data.data..", data?.data?.data);
        await setCompanies(data?.data?.data);
        // await setCompanies(updatedData);
      }
    } catch (err: any) {
      console.log("err...", err);
      const errMsg = errorHandler(err);
      error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const TagsFilterModal = (props: any) => {
    return (
      <Flex gap={"16px"} direction={"column"}>
        <Box
          position={"relative"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          h={"60px"}
        >
          <Text
            fontSize={"sm"}
            fontWeight={"regular"}
            textAlign={"center"}
            color={"black"}
          >
            Tags
          </Text>
          <Box
            position={"absolute"}
            right={0}
            top={0}
            py={3}
            cursor={"pointer"}
          >
            <MdClose
              size={20}
              onClick={() => {
                handleCloseTagsFilterModal();
              }}
            />
          </Box>
        </Box>
        {props.interestedTags && props.interestedTags?.length > 0 ? (
          <Flex alignItems={"center"} flexWrap={"wrap"} gap={"8px"}>
            {props.interestedTags.map((val: any, ind: number) => {
              if (props.selectedTags?.includes(val))
                return (
                  <Box
                    color={"#FFF"}
                    display={"flex"}
                    alignItems={"center"}
                    style={{
                      padding: "3px 7px",
                      color: "#FFF",
                      fontFamily: `"Inter Variable", sans-serif`,
                      fontWeight: "light",
                      backgroundColor: "#FF8D80",
                      borderRadius: "3px",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => {
                      let temp = props.selectedTags || [];
                      temp?.splice(temp?.indexOf(val), 1);
                      const arr = [...temp];
                      if (props.setSelectedTags) props?.setSelectedTags(arr);
                    }}
                  >
                    <Text fontSize={"sm"} fontWeight={500}>
                      {val}
                    </Text>
                    <IoClose />
                  </Box>
                );
              else
                return (
                  <Box
                    onClick={() => {
                      let temp = props.selectedTags || [];
                      temp?.push(val);
                      const arr = [...temp];
                      if (props.setSelectedTags) props?.setSelectedTags(arr);
                    }}
                    style={{
                      padding: "3px 7px",
                      color: "#000000",
                      fontFamily: `"Inter Variable", sans-serif`,
                      fontWeight: "light",
                      backgroundColor: "#FFD2CD",
                      borderRadius: "3px",
                    }}
                  >
                    <Text fontSize={"sm"} fontWeight={500}>
                      {val}
                    </Text>
                  </Box>
                );
            })}
          </Flex>
        ) : (
          <Text fontSize={"sm"} fontWeight={500}>
            No tags Found
          </Text>
        )}
      </Flex>
    );
  };

  const RelevanceFilterComponent = () => {
    return (
      <Select
        className="custom-select-noborder"
        style={{
          width: "100%",
          border: "0px",
          outline: "none",
          height: "100%",
          fontFamily: '"Inter Variable", sans-serif',
        }}
        dropdownStyle={{
          width: "auto",
          fontFamily: '"Inter Variable", sans-serif',
        }}
        onChange={(e) => {
          setRelevanceSort(e);
        }}
        // optionFilterProp="children"
        value={relevanceSort}
        dropdownRender={(menu) => (
          <div style={{ lineHeight: 0, margin: 0, padding: 0 }}>{menu}</div>
        )}
        // options={[
        //   {
        //     label: "All",
        //     value: "",
        //   },
        //   {
        //     label: (
        //       <Box
        //         height={18}
        //         width={18}
        //         lineHeight={0}
        //         margin={0}
        //         padding={0}
        //         borderRadius={"2px"}
        //         background={RelevanceFieldsNew.RELEVANT.colorHash}
        //       ></Box>
        //     ),
        //     value: RelevanceFieldsNew.RELEVANT.status,
        //   },
        //   {
        //     label: (
        //       <Box
        //         height={18}
        //         width={18}
        //         borderRadius={"2px"}
        //         background={RelevanceFieldsNew.EXISTING.colorHash}
        //       ></Box>
        //     ),
        //     value: RelevanceFieldsNew.EXISTING.status,
        //   },
        //   {
        //     label: (
        //       <Box
        //         height={18}
        //         width={18}
        //         borderRadius={"2px"}
        //         background={RelevanceFieldsNew.REVIEW.colorHash}
        //       ></Box>
        //     ),
        //     value: RelevanceFieldsNew.REVIEW.status,
        //   },
        //   {
        //     label: (
        //       <Box
        //         height={18}
        //         width={18}
        //         borderRadius={"2px"}
        //         background={RelevanceFieldsNew.NOTRELEVANT.colorHash}
        //       ></Box>
        //     ),
        //     value: RelevanceFieldsNew.NOTRELEVANT.status,
        //   },
        // ]}
        options={[
          {
            label: "All",
            value: "",
          },
          {
            label: RelevanceFieldsNew.RELEVANT.title,
            value: RelevanceFieldsNew.RELEVANT.status,
          },
          {
            label: RelevanceFieldsNew.EXISTING.title,
            value: RelevanceFieldsNew.EXISTING.status,
          },
          {
            label: RelevanceFieldsNew.REVIEW.title,
            value: RelevanceFieldsNew.REVIEW.status,
          },
          {
            label: RelevanceFieldsNew.NOTRELEVANT.title,
            value: RelevanceFieldsNew.NOTRELEVANT.status,
          },
        ]}
      />
    );
  };

  const isNewMeetingAllowed = (status: string) => {
    if (
      status === MEETING_REQUEST_STATUS.CANCELLED ||
      status === MEETING_REQUEST_STATUS.EXPIRED ||
      status === MEETING_REQUEST_STATUS.CLOSED
    ) {
      return true;
    }

    return false;
  };

  const LoadStates = async () => {
    const oSort = searchParams.get("o");
    const rSort = searchParams.get("r");
    // const sSort = searchParams.get('s');

    await setOrderSort(oSort || "a-to-z");
    await setRelevanceSort(rSort || "");

    console.log(isInitialRender.current);
    if (oSort && rSort) getExhibitorCompanies(inputs?.event, oSort || "");
    console.log(isInitialRender.current);
  };

  useEffect(() => {
    const oSort = searchParams.get("o");
    const rSort = searchParams.get("r");
    // const sSort = searchParams.get('s');

    setOrderSort(oSort || "a-to-z");
    setRelevanceSort(rSort || "");

    console.log(isInitialRender.current);
    isInitialRender.current = false;
    // if (oSort) getExhibitorCompanies(inputs?.event, oSort || "");
    console.log(isInitialRender.current);

    dispatch(
      actions.visitorMenu.set({
        activeMenu: VisitorScreens.DETAILS,
      })
    );
  }, []);

  useEffect(() => {
    setSearchParams({ o: orderSort });
  }, [orderSort]);

  useEffect(() => {
    getEvents();
  }, []);

  useEffect(() => {
    if(inputs.event){
      getInterestedTags();
    }
  }, [inputs?.event]);

  useEffect(() => {
    if (isInitialRender.current == false && inputs?.event) {
      console.log("logging after initial render");

      console.log(isInitialRender.current);

      getExhibitorCompanies(inputs?.event);
    }
  }, [inputs?.event, orderSort, relevanceSort, selectedTags, resetSearch]);

  const getAssets = async (exhibitorId: string) => {
    try {
      const res = await ExhibitorAPI.getAssetsByUserId(exhibitorId);
      if (res) {
        dispatch(actions.exhibitorAssets.setAssets({ links: res.data.data }));
        handleOpenDocsModal();
      }
    } catch (err) {
      console.log(err);
      error(errorHandler(err));
    }
  };

  return (
    <Box
      w={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      minH={"100vh"}
      maxH={"100vh"}
      px={"30px"}
      bg={"body.bg"}
    >
      {loading && <Loader />}

      {/* Modal for sort */}
      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={sortModal}
        onOk={handleCloseSortModal}
        onCancel={handleCloseSortModal}
      >
        <SortModal
          handleCloseModal={handleCloseSortModal}
          orderSort={orderSort}
          setOrderSort={setOrderSort}
        />
      </Modal>

      {/* Modal for meet confirm */}
      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={meetConfirm}
        onOk={handleCloseMeetConfirmModal}
        onCancel={handleCloseMeetConfirmModal}
      >
        <ConfirmationModal
          handleCloseModal={handleCloseMeetConfirmModal}
          handleSubmit={() => {
            navigate("/visitor/calendar?id=" + currentMeet);
          }}
          title={"Open meeting already exists"}
          message={"Please view & edit the same.  View details now ?"}
          confirmBtnText={"Yes, View"}
          rejectButtonText={"No"}
        />
      </Modal>

      {/* Modal for Tags */}
      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={tagsModel}
        onOk={handleCloseTagsModal}
        onCancel={handleCloseTagsModal}
      >
        <Box>
          <Box
            position={"relative"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            h={"60px"}
          >
            <Text
              fontSize={"sm"}
              fontWeight={"regular"}
              textAlign={"center"}
              color={"black"}
            >
              Tags
            </Text>
            <Box
              position={"absolute"}
              right={0}
              top={0}
              py={3}
              cursor={"pointer"}
            >
              <MdClose
                size={20}
                onClick={() => {
                  handleCloseTagsModal();
                }}
              />
            </Box>
          </Box>

          <Flex flexWrap={"wrap"} gap={2} my={8}>
            {/* {["ONE", "TWO", "THREE", "FOUR"]?.map((tag: any) => ( */}
            {tagsData?.map((tag: any) => (
              // <span className="p-1 bg-gray-400 text-white rounded-md whitespace-nowrap">
              <span
                className="whitespace-nowrap"
                style={{
                  padding: "3px 7px",
                  color: "#000000",
                  fontFamily: `"Inter Variable", sans-serif`,
                  fontWeight: "light",
                  backgroundColor: "#FFD2CD",
                  borderRadius: "3px",
                }}
              >
                {tag}
              </span>
            ))}
          </Flex>
        </Box>
      </Modal>

      {/* Modal for Filter */}
      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={filterModal}
        onOk={handleCloseFilterModal}
        onCancel={handleCloseFilterModal}
      >
        <FilterModal
          handleCloseModal={handleCloseFilterModal}
          relevanceSort={relevanceSort}
          role="Visitor"
          setRelevanceSort={setRelevanceSort}
          interestedTags={interestedTags}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
      </Modal>

      {/* Modal for Tags filter */}
      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={tagsFilter}
        onOk={handleCloseTagsFilterModal}
        onCancel={handleCloseTagsFilterModal}
      >
        <TagsFilterModal
          handleCloseModal={handleCloseTagsFilterModal}
          interestedTags={interestedTags}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
      </Modal>

      {/* Modal for Meet */}
      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={meetModal}
        onOk={handleCloseMeetModal}
        onCancel={handleCloseMeetModal}
      >
        <CreateMeetModal
          // meetRequesterPhone={visitorData.visitorPhoneNumber}
          responderId={meetResponder?.exhibitors?.userId}
          company={meetResponder?.companyName}
          eventId={meetResponder?.eventId}
          handleCloseModal={handleCloseMeetModal}
        />
      </Modal>

      {/* Modal for relevance */}
      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={releModel}
        onOk={handleCloseRelevanceModel}
        onCancel={handleCloseRelevanceModel}
      >
        <RelevanceModalVisitor
          data={relevanceData}
          relaodData={() => getExhibitorCompanies(inputs?.event)}
          handleCloseModal={handleCloseRelevanceModel}
        />
      </Modal>

      {/* Modal for notes */}
      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={notesModal}
        onOk={handleCloseNotesModal}
        onCancel={handleCloseNotesModal}
      >
        <NotesModal
          eventId={notesData.eventId}
          forUser={notesData.forUser}
          handleCloseModal={handleCloseNotesModal}
        />
      </Modal>

      {/* Modal for docs */}
      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={docsModal}
        onOk={handleCloseDocsModal}
        onCancel={handleCloseDocsModal}
      >
        <ViewDocsModal
          exhibitorName={personName}
          docLinks={exhibitorAsset.links}
          handleCloseModal={handleCloseDocsModal}
        />
      </Modal>

      <Flex
        mt={"30px"}
        alignItems={"center"}
        justifyContent={"space-between"}
        w={"100%"}
      >
        <VStack w={"100%"} textAlign={"left"} alignItems={"flex-start"}>
          <Text fontSize={"sm"} fontWeight={"semibold"}>
            {visitorData?.visitorPersonName || "Visitor Name"}
          </Text>
          <Text fontSize={"sm"} fontWeight={"regular"}>
            {visitorData?.visitorCompanyName || "Company Name"}
          </Text>
        </VStack>
        <Button
          w={"40px"}
          h={"40px"}
          borderRadius={"10px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          bg={"gray.100"}
          fontSize={"20px"}
          onClick={() => {
            navigate("/exhibitor/notifications");
          }}
        >
          <span>
            <IoMdNotificationsOutline size={22} />
          </span>
        </Button>
      </Flex>

      <VStack w={"100%"} mt={"30px"} spacing={2} alignItems={"end"}>
        <Flex
          border={"1px solid #E2E8F0"}
          borderRadius={"10px"}
          // padding={"0 15px"}
          w={"100%"}
          alignItems={"center"}
        >
          {/* <TfiWorld size={22} /> */}
          <Select
            className="custom-select-noborder"
            showSearch
            style={{
              width: "100%",
              border: "0px",
              outline: "none",
              height: "50px",
              fontFamily: '"Inter Variable", sans-serif',
              backgroundColor: "#f1edd0",
            }}
            value={inputs?.event}
            onChange={(e) => {
              setInputs({ ...inputs, event: e });
            }}
            placeholder="Event Name"
            optionFilterProp="children"
            options={[
              ...event?.map((event, index) => ({
                label:
                  event?.eventName +
                  "(" +
                  moment(event?.startDate).format("DD/MMM/YY") +
                  "-" +
                  moment(event?.endDate).format("DD/MMM/YY") +
                  ")",
                value: event?._id,
              })),
            ]}
          />
        </Flex>

        <Flex
          border={"1px solid #E2E8F0"}
          borderRadius={"10px"}
          // padding={"0 15px"}
          w={"100%"}
          alignItems={"center"}
          bg={"white"}
        >
          {/* <BiSearch size={22} /> */}
          <form
            style={{ width: "100%" }}
            onSubmit={(e) => {
              e.preventDefault();
              getExhibitorCompanies(inputs?.event);
            }}
          >
            <Input
              placeholder="Company Name"
              type="text"
              _placeholder={{
                color: "gray.500",
                fontWeight: "400",
                fontSize: "16px",
              }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              border={"0px"}
              outline={"none"}
              _focus={{ outline: "none", border: "0px" }}
              h={"50px"}
              w={"100%"}
              px={2}
              borderColor={"gray.300"}
            />
          </form>
        </Flex>
        <Button
          onClick={() => {
            getExhibitorCompanies(inputs?.event);
          }}
          colorScheme="transparent"
          variant={"outline"}
          borderColor={"card.borderDark"}
          w={"60px"}
          _hover={{ background: "secondary.light" }}
          h={"30px"}
        >
          <Text fontSize={"sm"} fontWeight={"regular"} color={"secondary.main"}>
            Search
          </Text>
        </Button>
      </VStack>

      <Flex
        // mt={"30px"}
        w={"100%"}
        justifyContent={"space-between"}
        // alignItems={"center"}
        flexDirection={"column"}
      >
        <Text fontSize={"md"} fontWeight={500}>
          {companies?.length} Exhibitor companies found
        </Text>
        <Text
          mb={"4px"}
          fontSize={"xs"}
          fontWeight={"regular"}
          color={"text.caption"}
        >
          Click on company name for more details.
          <br /> Click on the color icon to change relevance.
        </Text>
        <Flex gap={3} alignItems={"center"} mt={"10px"}>
          {/* <BiFilter
            size={22}
            cursor={"pointer"}
            onClick={handleOpenFilterModal}
            style={{
              background: relevanceSort
                ? relevanceFields.find((s) => s.status == relevanceSort)
                    ?.colorHash
                : "",
              borderRadius: "2px",
              padding: relevanceSort ? "2px" : "",
              color: relevanceSort ? "#FFFFFF" : "",
            }}
          /> */}
          <FilterToggleButton
            buttonText="A-Z"
            buttonRef={filterToggleRef as React.LegacyRef<HTMLButtonElement>}
            onClick={() => {
              if (orderSort == "z-to-a") {
                setOrderSort("a-to-z");
              } else {
                setOrderSort("z-to-a");
              }
            }}
          />
          <Flex
            bg={"white"}
            border={"1px solid #D9D9D9"}
            borderColor={"card.borderLight"}
            borderRadius={"md"}
            height={"25px"}
            alignItems={"center"}
            justifyContent={"center"}
            padding={"0 5px"}
          >
            <Text fontWeight={"regular"} fontSize={"xs"}>
              Relevance
            </Text>
            <RelevanceFilterComponent />
          </Flex>
          <Button
            onClick={() => {
              handleOpenTagsFilterModal();
            }}
            colorScheme="transparent"
            variant={"outline"}
            borderColor={
              selectedTags.length ? "primary.main" : "card.borderLight"
            }
            backgroundColor={selectedTags.length ? "primary.main" : "white"}
            // _hover={{ background: "secondary.light" }}
            h={"25px"}
          >
            <Text
              fontSize={"xs"}
              fontWeight={"regular"}
              color={selectedTags.length ? "secondary.main" : "text.black2"}
            >
              Tags
            </Text>
          </Button>
          <Button variant={"link"} onClick={resetFilters}>
            <Text fontSize={"sm"} fontWeight={"normal"} color={"#006CFF"}>
              Reset
            </Text>
          </Button>
          {/* <BiSort size={22} onClick={handleOpenSortModal} cursor={"pointer"} /> */}
        </Flex>
      </Flex>

      <Box
        w={"100%"}
        mt={"30px"}
        h={"100%"}
        overflowY={"auto"}
        overflowX={"hidden"}
        display={"flex"}
        flexDirection={"column"}
        gap={"20px"}
        pb={"100px"}
      >
        {companies?.map((item: any, index: any) => (
          <>
            <Box
              p={"15px"}
              _hover={{
                shadow: "md",
              }}
              border={"1px solid #E2E8F0"}
              borderRadius={"15px"}
              shadow={"sm"}
              key={"exhibitor_" + index}
              bg={
                accordinActive == item?.exhibitors?.exhibitorId
                  ? "white"
                  : "transparent"
              }
              onClick={() => {
                if (accordinActive == item?.exhibitors?.exhibitorId) {
                  setAccordinActive("");
                } else {
                  setAccordinActive(item?.exhibitors?.exhibitorId);
                }
              }}
              // backgroundColor={"accordian_bg.main"}
            >
              <Flex
                justifyContent={"space-between"}
                alignItems={"center"}
                // onClick={() => {
                //   if (accordinActive == item?.exhibitors?.exhibitorId) {
                //     setAccordinActive("");
                //   } else {
                //     setAccordinActive(item?.exhibitors?.exhibitorId);
                //   }
                // }}
              >
                <Text fontWeight={"medium"} fontSize={"sm"}>
                  {item.companyName}
                </Text>
                <Box
                  height={18}
                  width={18}
                  borderRadius={"2px"}
                  background={
                    relevanceFields.find(
                      (s) => s.status === item.prequalificationStatus
                    )?.color
                  }
                  onClick={(e: any) => {
                    e.stopPropagation();
                    handleOpenRelevanceModel();
                    setRelevanceData({
                      prequalified_company_name: item.companyName,
                      prequalified_user_id: item.exhibitors.exhibitorId,
                      event_id: item.eventId,
                      status: item.prequalificationStatus,
                    });
                  }}
                ></Box>
                {/* <Flex gap={2} alignItems={"center"}>
                    <Box
                      height={18}
                      width={18}
                      borderRadius={"50%"}
                      background={
                        relevanceFields.find(
                          (s) => s.status === item.prequalificationStatus
                        )?.color
                      }
                      onClick={(e: any) => {
                        e.stopPropagation();
                        handleOpenRelevanceModel();
                        setRelevanceData({
                          prequalified_company_name: item.companyName,
                          prequalified_user_id: item.exhibitors.exhibitorId,
                          event_id: item.eventId,
                          status: item.prequalificationStatus,
                        });
                      }}
                    ></Box>
                    <Button colorScheme="transparent">
                      <span>
                        <FiEdit
                          onClick={() => {
                            setNotesData((prev: any) => ({
                              forUser: item?.exhibitors?.exhibitorId,
                              eventId: item?.eventId,
                            }));
                            handleOpenNotesModal();
                          }}
                          size={22}
                          color="#000"
                        />
                      </span>
                    </Button>
                  </Flex> */}
              </Flex>
              {/* <Flex>
              <Text fontSize={"sm"}>12.03.2023 19:03</Text>
            </Flex> */}
              {/* <Flex>
            <Text fontSize={"sm"}>Divisional Manager</Text>
          </Flex> */}
              {/* {item?.exhibitors?.exhibitorId != accordinActive ? (
                <Button
                  mx={"auto"}
                  p={"10px"}
                  w={"200px"}
                  bg={"#2869FE1a"}
                  color={"#2869FE"}
                  mt={"30px"}
                  className={"connect-button-top"}
                  onClick={() => {
                    navigate(
                      "/visitor/chat?user=" +
                        item?.exhibitors?.userId +
                        "&event=" +
                        item.eventId +
                        "&m=" +
                        item?.exhibitors.exhibitorId,
                      {
                        state: {
                          exhibitor: {
                            personName: item.exhibitors.personName,
                            personDesignation:
                              item.exhibitors.personDesignation,
                            companyName: item.companyName,
                          },
                        },
                      }
                    );
                  }}
                >
                  Connect
                </Button>
              ) : null} */}

              {/* Collapse */}
              <Box
                mt={3}
                overflow={
                  accordinActive == item.exhibitors.exhibitorId
                    ? "visible"
                    : "hidden"
                }
                opacity={accordinActive == item.exhibitors.exhibitorId ? 1 : 0}
                height={
                  accordinActive == item.exhibitors.exhibitorId ? "auto" : "0px"
                }
                transition={"all 0.5s ease-in-out"}
              >
                <VStack>
                  <Box width={"100%"}>
                    <Flex gap={2}>
                      {/* <Text fontWeight={600}>Person Name:</Text> */}
                      <Text fontSize={"sm"} fontWeight={"medium"} mb={"2px"}>
                        {item?.exhibitors?.personName}
                      </Text>
                    </Flex>
                    <Flex gap={2} mb={"6px"}>
                      {/* <Text fontWeight={600}>Person Designation:</Text> */}
                      <Text fontSize={"small"} fontWeight={"light"}>
                        {item?.exhibitors?.personDesignation}
                      </Text>
                    </Flex>
                    <Flex gap={2} mb={"2px"}>
                      <Text fontSize={"small"} fontWeight={"medium"}>
                        Booth Number:
                      </Text>
                      <Text fontSize={"small"} fontWeight={"light"}>
                        {item?.exhibitors?.exhibitorBoothNumberIdentifier?.map(
                          (booth: any, index: any) => (
                            <span className="p-1 rounded-md mr-2">
                              {index > 0 ? ", " : ""}
                              {booth}
                            </span>
                          )
                        )}
                      </Text>
                    </Flex>
                    {/* <Flex gap={2} mb={"2px"}>
                      <Text fontSize={"small"} fontWeight={"medium"}>
                        Website:
                      </Text>
                      <Text fontSize={"small"} fontWeight={"light"}>
                        {item?.exhibitors?.companyUrl || ""}
                      </Text>
                    </Flex> */}
                    <Flex gap={2} mb={"2px"}>
                      <Text fontSize={"small"} fontWeight={"medium"}>
                        Address:
                      </Text>
                      <Text fontSize={"small"} fontWeight={"light"}>
                        {item?.exhibitors?.companyHqCity
                          ? item?.exhibitors?.companyHqCity + ","
                          : ""}
                        {item?.exhibitors?.companyHqState
                          ? item?.exhibitors?.companyHqState + ", "
                          : ""}
                        {item?.exhibitors?.companyHqCountry
                          ? item?.exhibitors?.companyHqCountry
                          : ""}
                      </Text>
                    </Flex>
                    <Flex gap={2} mb={"2px"}>
                      <Text
                        whiteSpace={"nowrap"}
                        fontSize={"small"}
                        fontWeight={"medium"}
                      >
                        Tags:
                      </Text>
                      <Text
                        display={"flex"}
                        flexWrap={"wrap"}
                        alignItems={"center"}
                        gap={2}
                      >
                        {item?.exhibitors?.productTypeTags
                          ?.filter((v: any, index: number) => index < 2)
                          ?.map((tag: any) => (
                            // <span className="p-1 bg-gray-400 text-white rounded-md whitespace-nowrap">
                            <span
                              style={{
                                padding: "3px 7px",
                                color: "#000000",
                                fontFamily: `"Inter Variable", sans-serif`,
                                fontWeight: "normal",
                                backgroundColor: "#FFD2CD",
                                borderRadius: "3px",
                                height: "18px",
                                fontSize: "10px",
                              }}
                              className="whitespace-nowrap"
                            >
                              {tag}
                            </span>
                          ))}
                        {item?.exhibitors?.productTypeTags?.length > 2 ? (
                          <button
                            className="text-[#2869FE] text-start"
                            onClick={() => {
                              setTagsData(item?.exhibitors?.productTypeTags);
                              handleOpenTagsModal();
                            }}
                          >
                            ...more
                          </button>
                        ) : null}
                      </Text>
                    </Flex>
                    <Flex gap={2} mb={"2px"}>
                      <Text fontSize={"small"} fontWeight={"medium"}>
                        Notes :{" "}
                      </Text>
                      <Link
                        color={"card.borderBlue"}
                        onClick={() => {
                          setNotesData((prev: any) => ({
                            forUser: item?.exhibitors?.exhibitorId,
                            eventId: item?.eventId,
                          }));
                          handleOpenNotesModal();
                        }}
                      >
                        <Text color={"card.borderBlue"} fontSize={"sm"}>
                          &nbsp;View/Add
                        </Text>
                      </Link>
                    </Flex>
                    <Flex gap={2} mb={"2px"}>
                      <Text fontSize={"small"} fontWeight={"medium"}>
                        Digital Assets :{" "}
                      </Text>
                      <Link
                        color={"card.borderBlue"}
                        onClick={() => {
                          setPersonName(item.companyName);
                          getAssets(item.exhibitors.userId);
                        }}
                      >
                        <Text color={"card.borderBlue"} fontSize={"sm"}>
                          Documents, Images & Videos
                        </Text>
                      </Link>
                    </Flex>
                  </Box>
                  <Flex width={"100%"} justifyContent={"space-around"}>
                    {item?.exhibitors?.companyUrl && (
                      <VStack gap={0}>
                        <Button
                          colorScheme="transparent"
                          onClick={() => {
                            window.open(item?.exhibitors?.companyUrl, "_blank");
                          }}
                        >
                          <span>
                            <FaChrome size={22} color={"#F5793D"} />
                          </span>
                        </Button>
                        <Text fontWeight={"regular"} fontSize={"xs"}>
                          Site
                        </Text>
                      </VStack>
                    )}
                    {item?.exhibitors?.isPlanActive && (
                      <VStack gap={0}>
                        <Button
                          colorScheme="transparent"
                          onClick={() => {
                            // console.log("meetingData...", meetingData);

                            if (!visitorData.visitorPhoneNumber)
                              return error(
                                "Please provide your contact number in the profile page"
                              );

                            if (meetingData[item?.exhibitors.companyName]) {
                              let meetingObj =
                                meetingData[item?.exhibitors.companyName];
                              // console.log("meetingObj...", meetingObj);
                              if (
                                isNewMeetingAllowed(
                                  user.user === meetingObj.requesterId
                                    ? meetingObj.requesterStatus
                                    : meetingObj.responderStatus
                                )
                              ) {
                                // navigate('/meet', {state:{exhibitorId: item?.exhibitors?.exhibitorId}})
                                setMeetResponder(item);
                                handleOpenMeetModal();
                              } else {
                                handleOpenMeetConfirmModal();
                                setCurrentMeet(meetingObj._id);
                              }
                            } else {
                              // navigate('/meet', {state:{exhibitorId: item?.exhibitors?.exhibitorId}})
                              setMeetResponder(item);
                              handleOpenMeetModal();
                            }
                          }}
                        >
                          <MdCalendarToday color={"#F5793D"} size={22} />
                        </Button>
                        <Text fontWeight={"regular"} fontSize={"xs"}>
                          Meet
                        </Text>
                      </VStack>
                    )}
                    {item?.exhibitors?.isPlanActive && (
                      <VStack gap={0}>
                        <Button
                          colorScheme="transparent"
                          onClick={() => {
                            navigate(
                              "/visitor/chat?user=" +
                                item?.exhibitors?.userId +
                                "&event=" +
                                item.eventId +
                                "&m=" +
                                item?.exhibitors.exhibitorId,
                              {
                                state: {
                                  exhibitor: {
                                    personName: item.exhibitors.personName,
                                    personDesignation:
                                      item.exhibitors.personDesignation,
                                    companyName: item.companyName,
                                  },
                                },
                              }
                            );
                          }}
                        >
                          <span>
                            <MdChat size={22} color={"#F5793D"} />
                          </span>
                        </Button>
                        <Text fontWeight={"regular"} fontSize={"xs"}>
                          Chat
                        </Text>
                      </VStack>
                    )}
                  </Flex>
                </VStack>
              </Box>
              {/* <Flex
                gap={2}
                alignItems={"center"}
                justifyContent={"space-between"}
                mt={2}
              >
                <Button colorScheme="transparent">
                  <span>
                    <FaRegCalendarAlt
                      onClick={() => {
                        // navigate('/meet', {state:{exhibitorId: item?.exhibitors?.exhibitorId}})
                        setMeetResponder(item);
                        handleOpenMeetModal();
                      }}
                      size={22}
                      color="#000"
                    />
                  </span>
                </Button>
                <Button colorScheme="transparent">
                  <span>
                    <IoChatboxEllipsesOutline
                      onClick={() => {
                        navigate(
                          "/visitor/chat?user=" +
                            item?.exhibitors?.userId +
                            "&event=" +
                            item.eventId +
                            "&m=" +
                            item?.exhibitors.exhibitorId,
                          {
                            state: {
                              exhibitor: {
                                personName: item.exhibitors.personName,
                                personDesignation:
                                  item.exhibitors.personDesignation,
                                companyName: item.companyName,
                              },
                            },
                          }
                        );
                      }}
                      size={22}
                      color="#000"
                    />
                  </span>
                </Button>
                {/* <Button
                    mx={"auto"}
                    p={"10px"}
                    w={"200px"}
                    bg={"#2869FE1a"}
                    color={"#2869FE"}
                    mt={"30px"}
                    onClick={() => {
                      navigate(
                        "/visitor/chat?user=" +
                          item?.exhibitors?.userId +
                          "&event=" +
                          item.eventId +
                          "&m=" +
                          item?.exhibitors.exhibitorId,
                        {
                          state: {
                            exhibitor: {
                              personName: item.exhibitors.personName,
                              personDesignation:
                                item.exhibitors.personDesignation,
                              companyName: item.companyName,
                            },
                          },
                        }
                      );
                    }}
                  >
                    Connect
                  </Button> 
              </Flex> */}
            </Box>
          </>
        ))}
      </Box>
    </Box>
  );
};

export default VisitorHome;
