import { Box, Button, Flex, Text, Textarea } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AdminVisitor } from "../../Interface/AdminInterfaces";
import { AdminAPI } from "../../Apis/adminAPI";
import Loader from "../../Components/Loader/Loader";
import { MdArrowDropDown, MdArrowDropDownCircle } from "react-icons/md";
import {
  EventInterface,
  VisitorInterface,
} from "../../Interface/CommonInterface";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { CommonAPI } from "../../Apis/commonApi";
import { IoMdArrowDropright } from "react-icons/io";
import { Select } from "antd";
import { useToastWrapper } from "../../Wrapper/toastWrapper";

type Props = {};

const VisitorRewards = (props: Props) => {
  const location = useLocation();
  const { visitorId } = location.state || {};
  const { error } = useToastWrapper();
  const [loading, setLoading] = useState(false);
  const [rewardStatements, setRewardStatements] = useState<any[]>([]);
  const [rewardRedemptions, setRewardRedemptions] = useState<any[]>([]);
  const [redemmablePoints, setRedemmablePoints] = useState<number>(0);
  const [accordian, setAccordian] = useState<number>(-1);
  const [progressDropDown, setProgressDropDown] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [uniqueRedemptionRequestIds, setUniqueRedemptionRequestIds] = useState<
    any[]
  >([]);
  const [event, setEvent] = useState<EventInterface>();
  const [visitor, setVisitor] = useState<VisitorInterface>();

  const getRewardsAndEvents = async () => {
    try {
      setLoading(true);
      const [res1, res2] = await Promise.all([
        AdminAPI.getEvent(),
        AdminAPI.getVisitorById(visitorId),
      ]);
      if (res1) {
        setEvent(res1.data.data[0]);
      }
      if (res2) {
        const params: any = {};
        params["visitorId"] = res2.data.data._id;
        setVisitor(res2.data.data);
        const [data1, data2] = await Promise.all([
          AdminAPI.getRewardsByVisitorId(params),
          AdminAPI.getRewardsRedemption(params),
        ]);
        if (data1) {
          setRewardStatements(data1.data.data.rewardsStatements);
        }
        if (data2) {
          setRewardRedemptions(data2.data.data.rewardsRedemption);
        }
      }
    } catch (err: any) {
      console.log(err);
    } finally {
      setAccordian(-1);
      setLoading(false);
    }
  };
  const getRedemmablePoints = () => {
    setRedemmablePoints(rewardStatements[0].closingBalance);
  };

  const updateRedemptionRequest = async (requestId: string) => {
    if(progressDropDown == ''){
      error("Redemption Status not selected", "");
      return;
    }
    if(notes == ''){
      error("Please fill the notes", "");
      return;
    }
    try {
      const res = await AdminAPI.updateRewardsRedemption({
        eventId: event?._id,
        visitorId: visitor?._id,
        redemptionRequestId: requestId,
        redemptionStatus: progressDropDown,
        redemptionNotes: notes,
      });
      if (res) {
        console.log(res);
        setProgressDropDown("");
        setNotes("");
        await getRewardsAndEvents();
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getRewardsAndEvents();
  }, []);
  useEffect(() => {
    if (rewardStatements.length > 0) {
      getRedemmablePoints();
    }
    if (rewardRedemptions.length > 0) {
      const uniqueIds = rewardRedemptions.reduce<string[]>((acc, item) => {
        if (!acc.includes(item.redemptionRequestId)) {
          acc.push(item.redemptionRequestId);
        }
        return acc;
      }, []);
      setUniqueRedemptionRequestIds(uniqueIds);
    }
  }, [rewardRedemptions]);
  return (
    <div className="p-4">
      {loading ? <Loader /> : null}

      <Flex
        justifyContent={"center"}
        mt={"10px"}
        padding={"10px 0px"}
        backgroundColor={"primary.main"}
      >
        <Text fontSize={"24px"} fontWeight={600} className="text-[#FFFFFF]">
          Visitor Rewards
        </Text>
      </Flex>
      <Flex
        justifyContent={"space-around"}
        padding={"10px 0px"}
        borderBottomColor={"primary.main"}
        borderBottom={"2px solid"}
      >
        <Text size={"lg"} fontWeight={600}>
          {event?.eventName}
        </Text>
        <Text size={"lg"} fontWeight={600}>
          {visitor?.visitorPersonName}
        </Text>
        <Text size={"lg"} fontWeight={600}>
          {visitor?.visitorCompanyName}
        </Text>
      </Flex>
      <Flex
        justifyContent={"space-around"}
        padding={"10px 0px"}
        borderBottomColor={"primary.main"}
        borderBottom={"2px solid"}
      >
        <Text size={"lg"} fontWeight={600}>
          Reward Points Received:{" "}
          {rewardStatements
            ?.filter((item) => item.txnType === "Add")
            .reduce((sum, item) => sum + item.points, 0)}
        </Text>
        <Text size={"lg"} fontWeight={600}>
          Redemption In Progress: {rewardStatements[0]?.redemptionInProgress}
        </Text>
        <Text size={"lg"} fontWeight={600}>
          Redeemed: {rewardStatements[0]?.pointsRedeemed}
        </Text>
      </Flex>
      <Flex justifyContent={"center"} mt={"10px"} padding={"10px 0px"}>
        <Text fontSize={"24px"} fontWeight={600}>
          Statement
        </Text>
      </Flex>
      <Box>
        {uniqueRedemptionRequestIds.map((field, index) => (
          <Box>
            <Flex
              alignItems={"center"}
              gap={"40px"}
              mt={"10px"}
              padding={"10px 0px"}
            >
              {accordian != index ? (
                <IoMdArrowDropright
                  size={"70px"}
                  onClick={() => {
                    setAccordian(index);
                  }}
                />
              ) : (
                <MdArrowDropDown
                  size={"70px"}
                  onClick={() => {
                    setAccordian(-1);
                  }}
                />
              )}

              <Text size={"lg"} fontWeight={600}>
                {field}
              </Text>
              <Text size={"lg"} fontWeight={600}>
                {
                  rewardRedemptions.filter(
                    (item) => item.redemptionRequestId === field
                  )[0].redemptionStatus
                }
              </Text>
            </Flex>
            {accordian == index && (
              <Box
                sx={{
                  paddingLeft: "50px",
                }}
              >
                <Text paddingLeft={"10px"}>Redemption Status</Text>
                <Flex gap={"40px"} mt={"10px"} padding={"10px"}>
                  <Select
                    className="custom-select-noborder"
                    showSearch
                    style={{
                      width: "30%",
                      border: "1px solid #D9D9D9",
                      height: "50px",
                    }}
                    value={progressDropDown}
                    onChange={(e) => {
                      setProgressDropDown(e);
                    }}
                    placeholder="Redemption Status"
                    optionFilterProp="children"
                    options={[
                      {
                        label: "In progress",
                        value: "In Progress",
                      },
                      {
                        label: "Completed",
                        value: "Completed",
                      },
                    ]}
                  />

                  <Textarea
                    style={{
                      width: "30%",
                      border: "1px solid #D9D9D9",
                      borderRadius: 0,
                    }}
                    value={notes}
                    onChange={(e) => {
                      setNotes(e.target.value);
                      console.log(e.target.value);
                    }}
                    rows={6}
                    placeholder="Enter Redemption Notes"
                  />
                  <Box display={"flex"} width={"30%"} justifyContent={"center"}>
                    <Button
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      background={"primary.main"}
                      color={"white"}
                      padding={"10px"}
                      onClick={() => {
                        updateRedemptionRequest(field);
                      }}
                    >
                      Add Notes
                    </Button>
                  </Box>
                </Flex>
                {rewardRedemptions
                  .filter((item) => item.redemptionRequestId === field)
                  .map((redemption, index) => (
                    <Flex
                      alignItems={"center"}
                      gap={"25%"}
                      mt={"10px"}
                      padding={"10px"}
                    >
                      <Text size={"lg"} fontWeight={600}>
                        {moment(redemption?.createdAt)?.format("DD/MMM/YYYY hh:mm A")}
                      </Text>
                      <Text size={"lg"} fontWeight={600}>
                        {redemption?.redemptionStatus}
                      </Text>
                      <Text size={"lg"} fontWeight={600}>
                        {redemption?.redemptionNotes}
                      </Text>
                    </Flex>
                  ))}
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default VisitorRewards;
