import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  Divider,
  Link,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Header from "../../Components/Common/Header";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import FAQModal from "../../Components/Modals/FAQModal";
import { useSelector } from "react-redux";
import { ExhibitorAPI } from "../../Apis/exhibitorAPI";
import { AdminAPI } from "../../Apis/adminAPI";
import Loader from "../../Components/Loader/Loader";
import { AiOutlineQuestionCircle } from "react-icons/ai";

type Props = {};

const Subscription = (props: Props) => {
  const [isModal, setIsModal] = useState(false);
  const exhibitorAsset = useSelector((state: any) => state.exhibitorAsset);
  const [userPlan, setUserPlan] = useState<any>({});
  const [exhibitorData, setExhibitorData] = useState<any>(null);
  const [eventName, setEventName] = useState<string>("");
  const user = useSelector((state: any) => state.user);

  const handleOpenModal = () => setIsModal(true);
  const handleCloseModal = () => setIsModal(false);

  const navigate = useNavigate();
  const getPlanDetails = async () => {
    try {
      let userId = user && user?.role == "Exhibitor" ? user.user : "";

      if (user && user?.role == "Exhibitor") {
        const [planDetails] = await Promise.all([
          ExhibitorAPI.getEventExhibitorProfile(userId),
        ]);
        if (planDetails) {
          const [EventDetails, exhibitorDetails] = await Promise.all([
            await AdminAPI.getEventById(planDetails?.data?.data?.eventId),
            await AdminAPI.getExhibitorById(
              planDetails?.data?.data?.exhibitorId
            ),
          ]);
          setExhibitorData(exhibitorDetails?.data?.data);
          setEventName(EventDetails?.data?.data?.eventName);
          setUserPlan(planDetails?.data?.data);
        }
      }
    } catch (err: any) {
      console.log(err);
    }
  };
  const sendRenewRequest = async () => {
    try {
      const res = await AdminAPI.requestSubscriptionPlan({
        eventName: eventName,
        exhibitorName: exhibitorData?.personName,
      });
      if (res) {
        console.log(res);
        navigate("/exhibitor/subscriptions-purchase");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const FAQButton = () => {
    return (
      <AiOutlineQuestionCircle
        style={{
          position: "absolute",
          right: "0px",
          top: "0px",
          height: "36px",
          width: "36px",
        }}
        // onClick={handleOpenModal}
        onClick={() => {
          window.open("https://prontocommerce.com/connect/subscriptions/", "_blank");
        }}
      />
    );
  };
  useEffect(() => {
    getPlanDetails();
  }, []);

  return (
    <Box p={6} display={"flex"} flexDir={"column"} w={"100%"}>
      <Modal
        footer={null}
        title={null}
        open={isModal}
        onCancel={handleCloseModal}
        closeIcon={null}
        onOk={handleCloseModal}
      >
        <FAQModal handleCloseModal={handleCloseModal} />
      </Modal>
      <Header backButton righticon={FAQButton}>Subscription</Header>
      <Divider
        mt={5}
        borderWidth="6px"
        opacity={"1"}
        borderColor={"primary.main"}
      />
      {exhibitorData ? (
        <Box p={6} maxW="800px" mx="auto">
          <VStack spacing={6} align="flex-start">
            <Text>
              <span style={{ fontWeight: "600" }}>Event Name:</span> {"  "}
              {eventName}
            </Text>
            <Text>
              <span style={{ fontWeight: "600" }}>Plan Name:</span> {"  "}
              {userPlan?.planName}
            </Text>
            <Text>
              <span style={{ fontWeight: "600" }}>Plan Status:</span> {"  "}
              {userPlan?.isPlanActive ? "Active" : "Inactive"}
            </Text>
            <Text>
              <span style={{ fontWeight: "600" }}>Pending Meetings:</span>{" "}
              {"  "}
              {userPlan?.pendingMeetingsCount} of{" "}
              {userPlan?.pendingMeetingsLimit}
            </Text>
            <Text fontSize={"sm"} fontStyle={"italic"} fontWeight={"light"}>
              The number of meeting requests that can be kept in pending state
              before creating or confirming a new meeting request. If this limit
              is reached, pending meeting requests have to be responded to,
              before confirming or creating new requests.
            </Text>
            <Text>
              <span style={{ fontWeight: "600" }}>Closed Meetings:</span> {"  "}
              {userPlan?.closedMeetingsCount} of {userPlan?.closedMeetingsLimit}
            </Text>
            <Text fontSize="sm" fontStyle={"italic"} fontWeight={"light"}>
              The number of meetings that are deemed completed.
            </Text>
            <Text>
              <span style={{ fontWeight: "600" }}>
                Products & Services Tags:
              </span>{" "}
              {"  "}
              {exhibitorData?.productTypeTags?.length} of{" "}
              {userPlan?.exhibitorTagsLimit}
            </Text>
            <Text fontSize="sm" fontStyle={"italic"} fontWeight={"light"}>
              The number of tags that can be created to represent your products
              and services.
            </Text>
            <Text>
              <span style={{ fontWeight: "600" }}>Digital Assets:</span> {"  "}
              {exhibitorAsset?.links?.length} of {userPlan?.digitalAssetsLimit}
            </Text>
            <Text fontSize={"sm"} fontStyle={"italic"} fontWeight={"light"}>
              The number of URLs of digital assets that can be added to the
              company profile.
            </Text>
            <Text
              fontStyle={"italic"}
              fontSize={"x-small"}
              fontWeight={"light"}
            >
              View{" "}
              <Link
                href="https://prontocommerce.com/connect/subscriptions/"
                isExternal
                textDecoration={"underline"}
              >
                T&Cs
              </Link>{" "}
              related to subscription plans.
            </Text>
          </VStack>
          <HStack mt={5} justifyContent={"center"}>
            {/* <Button
              flex={1}
              size={"md"}
              p={0}
              bg={"rgba(40,105,254,0.1)"}
              color={"#2869FE"}
              onClick={handleOpenModal}
            >
              FAQ
            </Button> */}
            <Button
              textAlign={"center"}
              bg={"secondary.light"}
              color={"secondary.main"}
              onClick={sendRenewRequest}
            >
              Renew / TopUp
            </Button>
            {/* <Button
              flex={1}
              size={"md"}
              p={0}
              bg={"rgba(40,105,254,0.1)"}
              color={"#2869FE"}
              onClick={() => {
                navigate("/exhibitor/subscriptions-statements");
              }}
            >
              Statement
            </Button> */}
          </HStack>
        </Box>
      ) : (
        <Loader />
      )}

      {/* <Box mt={5} py={7} bg={"#22121927"} rounded={"18px"}>
        <Text
          fontSize={"15px"}
          color={"text.black"}
          textAlign={"center"}
          fontWeight={"semibold"}
        >
          Connects Available
        </Text>
        <Text
          fontSize={"48px"}
          color={"text.black"}
          textAlign={"center"}
          fontWeight={"semibold"}
        >
          5
        </Text>
      </Box> */}
      {/* <Box mt={5}>
      <Button
          w={"100%"}
          h={"36px"}
          textAlign={"center"}
          bg={"secondary.light"}
          color={"secondary.main"}
          onClick={() => {
            navigate("/exhibitor/subscriptions-cart");
          }}
        >
          Buy more Connects
        </Button>
        <Text fontSize={"10px"} color={"text.black"} mt={1}>
          By clicking "Buy more Connects" you agree to these{" "}
          <span style={{ fontWeight: "800", cursor: "pointer" }}>T&C</span>
        </Text>
      </Box>
      <Box mt={8}>
        <Text fontSize={"15px"} fontWeight={"semibold"} color={"text.black"}>
          Latest Statements
        </Text>
        <VStack>
          {[0, 0, 0].map(() => (
            <Box
              w={"100%"}
              bg={"white"}
              p={3}
              rounded={"18px"}
              mt={3}
              px={5}
              py={4}
              display={"flex"}
              gap={4}
              justifyContent={"space-between"}
              alignItems={"start"}
              shadow={"md"}
              minH={"95px"}
            >
              <Box>
                <Text
                  fontSize={"15px"}
                  fontWeight={"semibold"}
                  color={"gray.600"}
                >
                  12 Dec 2023
                </Text>
                <Text fontSize={"12px"} mt={2} maxW={"200px"}>
                  Connects used
                </Text>
              </Box>
              <Text fontSize={"14px"} color={"red"}>
                -1
              </Text>
            </Box>
          ))}
        </VStack>
      </Box>
      <HStack justifySelf={"end"} mt={5}>
        <Button
          flex={1}
          size={"md"}
          p={0}
          bg={"rgba(40,105,254,0.1)"}
          color={"#2869FE"}
          onClick={handleOpenModal}
        >
          FAQ
        </Button>
        <Button
          flex={1}
          size={"md"}
          p={0}
          bg={"rgba(40,105,254,0.1)"}
          color={"#2869FE"}
          onClick={() => {
            navigate("/exhibitor/subscriptions-statements");
          }}
        >
          Statement
        </Button>
      </HStack> */}
    </Box>
  );
};

export default Subscription;
