import {
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Input,
  Text,
} from "@chakra-ui/react";
import { Modal, Table, DatePicker, Select } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useToastWrapper } from "../../Wrapper/toastWrapper";
import { AdminExhibitor } from "../../Interface/AdminInterfaces";
import { AdminAPI } from "../../Apis/adminAPI";
import Loader from "../../Components/Loader/Loader";
import UsersCsv from "../../Assets/exhibitor_bulk.csv";
import Pagination from "../../Components/Pagination/Pagination";
import { validateEmail } from "../../Utils/Validator";
import { errorHandler } from "../../Utils/handler";
import BulkUploadConfirmModal from "../../Components/Modals/BulkUploadConfirmModal";

type Props = {};

const mandatoryFields = [
  "eventId",
  "companyName",
  "personName",
  "personEmail",
  // "productTypeTags",
];

const Exhibitors = (props: Props) => {
  const PAGESIZE = 100;

  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isBulkConfirmModalVisible, setIsBulkConfirmModalVisible] =
    useState(false);
  const [eventName, setEventName] = useState("");

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [sortEventId, setSortEventId] = useState("");

  const showModal = () => setIsModalVisible(true);

  const handleOk = () => setIsModalVisible(false);

  // Bulk Upload Visitors Modal
  const [isBulkModalVisible, setIsBulkModalVisible] = useState(false);

  const showBulkModal = () => {
    setBulkDocument("");
    setBulkEventId("");
    setFileInputKey(Date.now());
    setIsBulkModalVisible(true);
  };

  const handleBulkOk = () => setIsBulkModalVisible(false);

  const showBulkConfirmModal = () => setIsBulkConfirmModalVisible(true);

  const hideBulkConfirmModal = () => setIsBulkConfirmModalVisible(false);

  const [bulkEventId, setBulkEventId] = useState("");
  const [bulkDocument, setBulkDocument] = useState<any>("");
  const [fileInputKey, setFileInputKey] = useState(Date.now());

  //Delete
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const showDeleteModal = () => setIsDeleteModalOpen(true);

  const handleDeleteModalOk = () => setIsDeleteModalOpen(false);

  const [deleteid, setDeleteId] = useState("");

  const [formData, setFormData] = useState<AdminExhibitor>({
    eventId: "",
    companyName: "",
    companyHqCity: "",
    companyHqState: "",
    companyHqCountry: "",
    companyUrl: "",
    personName: "",
    personEmail: "",
    personPhoneNumber: "",
    personDesignation: "",
    exhibitorBoothNumberIdentifier: [],
    productTypeTags: [],
    subscriptionId: "",
  });

  const [data, setData] = useState<any>([]);
  const [eventData, setEventData] = useState<any>([]);
  const [subscriptionsData, setSubscriptionsData] = useState<any>([]);

  const [edit, setEdit] = useState(false);
  const [editId, setEditId] = useState("");

  const getEventSubscriptions = async (eventId: string) => {
    setLoading(true);
    try {
      const res = await AdminAPI.getSubscriptions(eventId);
      const subs = res.data.data.subscriptions.map((item: any) => {
        return {
          label: item.planName,
          value: item._id,
        };
      });
      setSubscriptionsData(subs);
      if (res.success) {
      }
    } catch (err) {
      error(errorHandler(err));
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteOk = async (selectedRows: []) => {
    setLoading(true);

    const apiPromises: any[] = [];

    try {
      const arrResponse = await selectedRows.map(
        async (item: any, index: Number) => {
          const response = await handleDelete(item._id);
          apiPromises.push(response);
        }
      );

      Promise.all(apiPromises)
        .then((results) => {
          success("Exhibitors deleted successfully", "");
          getExhibitorsData();
        })
        .catch((err) => {
          error("Exhibitors delete failed");
        });
    } catch (err) {
      // console.log(err);
      error(errorHandler(err));
    } finally {
      setSelectedRowKeys([]);
      setSelectedRows([]);
      setLoading(false);
    }
  };

  const resetPasswordClick = async (selectedRows: []) => {
    setLoading(true);

    const apiPromises: any[] = [];

    try {
      if (selectedRows.length == 0) {
        error("Please select atleast one entry to reset !");
      } else {
        selectedRows.map(async (item: any, index: Number) => {
          console.log(index);
          const response = await handleResetPassword(item.userId);
          apiPromises.push(response);
        });
        Promise.all(apiPromises)
          .then((results) => {
            success("Password reset successfully", "");
          })
          .catch((err) => {
            error("Password reset failed");
          });
      }
    } catch (err) {
      // console.log(err);
      error(errorHandler(err));
    } finally {
      setLoading(false);
      setSelectedRowKeys([]);
      setSelectedRows([]);
    }
  };

  const handleResetPassword = async (userId: string) => {
    // setLoading(true);
    try {
      const res = await AdminAPI.resetPassword(userId);
      // if (res) success("Password reset successfully", "");
      return res;
    } catch (err) {
      // console.log(err);
      // error(errorHandler(err));
      throw err;
    } finally {
      // setLoading(false);
    }
  };

  const clearData = () => {
    setFormData({
      eventId: "",
      companyName: "",
      companyHqCity: "",
      companyHqState: "",
      companyHqCountry: "",
      companyUrl: "",
      personName: "",
      personEmail: "",
      personPhoneNumber: "",
      personDesignation: "",
      exhibitorBoothNumberIdentifier: [],
      productTypeTags: [],
    });
  };

  const handleDelete = async (key: any) => {
    // setLoading(true);
    try {
      const res = await AdminAPI.deleteExhibitor(key);
      // if (res) success("Exhibitor deleted successfully", "");
      // getExhibitorsData();
      return res;
    } catch (err) {
      // console.log(err);
      // error(errorHandler(err));
      throw err;
    } finally {
      // setLoading(false);
    }
  };

  const columns = [
    {
      title: "Event Name",
      dataIndex: "eventName",
      key: "eventName",
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      sorter: (a: any, b: any) => a.companyName.localeCompare(b.companyName),
    },
    {
      title: "Company HQ City",
      dataIndex: "companyHqCity",
      key: "companyHqCity",
    },
    {
      title: "Company HQ State",
      dataIndex: "companyHqState",
      key: "companyHqState",
    },
    {
      title: "Company HQ Country",
      dataIndex: "companyHqCountry",
      key: "companyHqCountry",
    },
    {
      title: "Company URL",
      dataIndex: "companyUrl",
      key: "companyUrl",
    },
    {
      title: "Person Name",
      dataIndex: "personName",
      key: "personName",
    },
    {
      title: "Email",
      dataIndex: "personEmail",
      key: "personEmail",
    },
    {
      title: "Phone Number",
      dataIndex: "personPhoneNumber",
      key: "personPhoneNumber",
    },
    {
      title: "Designation",
      dataIndex: "personDesignation",
      key: "personDesignation",
    },
    {
      title: "Booth Number",
      dataIndex: "exhibitorBoothNumberIdentifier",
      key: "exhibitorBoothNumberIdentifier",
      render: (exhibitorBoothNumberIdentifier: any) => (
        <Flex width={"150px"} flexWrap={"wrap"}>
          {exhibitorBoothNumberIdentifier?.map((tag: any) => (
            <Badge mr={2}>{tag}</Badge>
          ))}
        </Flex>
      ),
      sorter: (a: any, b: any) =>
        parseInt(a.exhibitorBoothNumberIdentifier[0]) -
        parseInt(b.exhibitorBoothNumberIdentifier[0]),
    },
    {
      title: "Product Type Tags",
      dataIndex: "productTypeTags",
      key: "productTypeTags",
      render: (productTypeTags: any) => (
        <Flex width={"150px"} flexWrap={"wrap"}>
          {productTypeTags?.map((tag: any) => (
            <Badge mr={2}>{tag}</Badge>
          ))}
        </Flex>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_: any, object: any) => (
        <>
          <Flex gap={1}>
            <Button
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              h={"100%"}
              background={"primary.main"}
              color={"white"}
              px={3}
              py={1}
              onClick={() => {
                setFormData({
                  eventId: object.eventId,
                  companyName: object.companyName,
                  companyHqCity: object.companyHqCity,
                  companyHqState: object.companyHqState,
                  companyHqCountry: object.companyHqCountry,
                  companyUrl: object.companyUrl,
                  personName: object.personName,
                  personEmail: object.personEmail,
                  personPhoneNumber: object.personPhoneNumber,
                  personDesignation: object.personDesignation,
                  exhibitorBoothNumberIdentifier:
                    object.exhibitorBoothNumberIdentifier,
                  productTypeTags: object.productTypeTags,
                  subscriptionId: object.subscriptionId,
                });
                setEdit(true);
                setEditId(object.exhibitorId);
                showModal();
                getEventSubscriptions(object.eventId);
              }}
            >
              Edit
            </Button>
            {/* <Button
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              h={"100%"}
              background={"button.green"}
              color={"white"}
              px={3}
              py={1}
              onClick={() => {
                handleResetPassword(object.userId);
              }}
            >
              Reset Password
            </Button>
            <Button
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              h={"100%"}
              background={"secondary.light"}
              color={"secondary.main"}
              px={3}
              py={1}
              onClick={() => {
                showDeleteModal();
                setDeleteId(object.exhibitorId);
              }}
            >
              Delete
            </Button> */}
          </Flex>
        </>
      ),
    },
  ];

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const { error, success } = useToastWrapper();

  const validate = () => {
    console.log(
      mandatoryFields.map((field) => formData[field as keyof AdminExhibitor])
    );
    if (
      mandatoryFields.some((field) => !formData[field as keyof AdminExhibitor])
    ) {
      error("Please fill all the mandatory fields", "");
      return false;
    }
    // if (formData?.productTypeTags?.length === 0) {
    //   error("Please fill all the mandatory fields", "");
    //   return false;
    // }
    if (!validateEmail(formData.personEmail)) {
      error("Please enter valid email", "");
      return false;
    }

    return true;
  };

  const getExhibitorsData = async () => {
    setLoading(true);
    try {
      const res = await AdminAPI.getEventExhibitor({
        eventId: sortEventId,
        limit: PAGESIZE,
        page: currentPage,
      });
      if (res) {
        console.log(res.data);
        setData(res.data.data);
        setTotal(res.data.totalCount);
      }
    } catch (err) {
      error(errorHandler(err));
    } finally {
      setLoading(false);
    }
  };

  const getData = async (eventId?: string) => {
    setLoading(true);
    try {
      const [data1] = await Promise.all([AdminAPI.getEvent()]);
      if (data1 && data1.data?.data?.length > 0) {
        setEventData(
          data1.data.data?.map((event: any) => ({
            label: event.eventName,
            value: event._id,
          }))
        );
      }
    } catch (err) {
      error(errorHandler(err));
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    if (edit) {
      setLoading(true);
      try {
        const res = await AdminAPI.putExhibitor(editId, formData);
        success("Exhibitor edited successfully", "");
        clearData();
        getExhibitorsData();
        handleOk();
        setEdit(false);
        setEditId("");
        if (res) {
        }
      } catch (err) {
        console.log(err);
        error(errorHandler(err));
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(true);
      try {
        const res = await AdminAPI.postExhibitor(formData);
        success("Exhibitor added successfully", "");
        clearData();
        getExhibitorsData();
        handleOk();
        if (res) {
        }
      } catch (err) {
        console.log(err);
        error(errorHandler(err));
      } finally {
        setLoading(false);
      }
    }
  };

  const handleBulkUpload = async () => {
    console.log("Bulk Upload");
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("file", bulkDocument);
      const res = await AdminAPI.bulkUploadUsers(formData, {
        role: "Exhibitor",
        eventId: bulkEventId,
      });
      if (res) {
        success("Exhibitors added successfully", "");
        clearData();
        getExhibitorsData();
        handleBulkOk();
        hideBulkConfirmModal();
      }
    } catch (err) {
      console.log(err);
      error(errorHandler(err));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getExhibitorsData();
  }, [sortEventId, currentPage]);

  // Function to handle row selection
  const onSelectChange = (selectedRowKeys: any, selectedRows: any) => {
    // console.log("Selected Row Keys:", selectedRows);
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const generateRowKey = (record: any) => record._id;

  return (
    <div className="p-4">
      {loading ? <Loader /> : null}

      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={isBulkConfirmModalVisible}
        onOk={hideBulkConfirmModal}
        onCancel={hideBulkConfirmModal}
      >
        <BulkUploadConfirmModal
          role={"exhibitor"}
          eventName={eventName}
          fileName={bulkDocument.name}
          handleCloseModal={hideBulkConfirmModal}
          handleSubmit={handleBulkUpload}
        />
      </Modal>

      {/* Delete */}
      <Modal
        title="Alert"
        footer={null}
        onCancel={handleDeleteModalOk}
        open={isDeleteModalOpen}
      >
        <Text my={5} mt={8}>
          {/* Deleting an Exhibitor will permanatly remove that Exhibitor. Are you
          sure ? */}
          Deleting exhibitors is permanent. Are you sure ?
        </Text>
        <Flex justifyContent={"space-between"}>
          <Button
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            h={"100%"}
            background={"primary.main"}
            color={"white"}
            px={3}
            py={1}
            onClick={() => {
              handleDeleteModalOk();
            }}
          >
            No
          </Button>
          <Button
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            h={"100%"}
            background={"secondary.light"}
            color={"secondary.main"}
            px={3}
            py={1}
            onClick={() => {
              // handleDelete(deleteid);
              handleDeleteOk(selectedRows);
              handleDeleteModalOk();
            }}
          >
            Yes
          </Button>
        </Flex>
      </Modal>

      {/* Bulk Upload Visitors */}
      <Modal
        footer={null}
        title="Bulk Add Exhibitors"
        width={"40%"}
        open={isBulkModalVisible}
        onCancel={handleBulkOk}
        onOk={handleBulkOk}
      >
        <Box p={2}>
          <Flex gap={2} alignItems={"center"}>
            <Text>Download Sample CSV File</Text>
            <a href={UsersCsv} download={true}>
              <Button
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                h={"100%"}
                background={"primary.main"}
                color={"white"}
                px={3}
                gap={2}
                py={2}
              >
                Download
              </Button>
            </a>
          </Flex>
          <Grid mt={3} gap={3} templateColumns="repeat(1, 1fr)">
            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Event Name
                </Text>
                <Select
                  placeholder="Select Event"
                  style={{
                    height: "40px",
                  }}
                  options={eventData}
                  onChange={(value, option: any) => {
                    // console.log(value);
                    setBulkEventId(value);
                    setEventName(option.label);
                  }}
                  value={bulkEventId}
                />
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Event
                </Text>
                <Input
                  type="file"
                  height="40px"
                  placeholder="Select File"
                  name="visitorPersonName"
                  key={fileInputKey}
                  // value={bulkDocument}
                  onChange={(e) => {
                    console.log(e.target.files);
                    if (e.target.files && e.target.files[0]) {
                      setBulkDocument(e.target.files[0]);
                    }
                  }}
                />
              </Box>
            </GridItem>
          </Grid>
          <Box mt={10}>
            {bulkDocument && bulkEventId ? (
              <Flex justifyContent={"end"}>
                <Button
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  h={"40px"}
                  background={"primary.main"}
                  color={"white"}
                  px={3}
                  gap={2}
                  onClick={showBulkConfirmModal}
                >
                  Submit
                </Button>
              </Flex>
            ) : null}
          </Box>
        </Box>
      </Modal>

      {/* Add Exhibitor */}
      <Modal
        footer={null}
        title={edit ? "Edit Exhibitor" : "Add Exhibitor"}
        width={"80%"}
        open={isModalVisible}
        onCancel={handleOk}
        onOk={handleOk}
      >
        <Box padding={2}>
          <Grid gap={4} templateColumns="repeat(3, 1fr)">
            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Event Name <span style={{ color: "red" }}>*</span>
                </Text>
                <Select
                  placeholder="Select Event"
                  style={{
                    height: "40px",
                  }}
                  value={formData.eventId}
                  options={[{ value: "", label: "Select Event" }, ...eventData]}
                  onChange={(value) => {
                    console.log(value);

                    getEventSubscriptions(value);

                    setFormData({
                      ...formData,
                      eventId: value,
                    });
                  }}
                />
              </Box>
            </GridItem>

            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Company Name <span style={{ color: "red" }}>*</span>
                </Text>
                <Input
                  height="40px"
                  placeholder="Enter Compnay Name"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>

            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Company HQ City
                </Text>
                <Input
                  height="40px"
                  placeholder="Enter Company HQ City"
                  name="companyHqCity"
                  value={formData.companyHqCity}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>

            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Company HQ State
                </Text>
                <Input
                  height="40px"
                  placeholder="Enter Company HQ State"
                  name="companyHqState"
                  value={formData.companyHqState}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>

            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Company HQ Country
                </Text>
                <Input
                  height="40px"
                  placeholder="Enter Company HQ Country"
                  name="companyHqCountry"
                  value={formData.companyHqCountry}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>

            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Company URL
                </Text>
                <Input
                  height="40px"
                  placeholder="Enter Company URL"
                  name="companyUrl"
                  value={formData.companyUrl}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>

            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Person Name <span style={{ color: "red" }}>*</span>
                </Text>
                <Input
                  height="40px"
                  placeholder="Enter Person Name"
                  name="personName"
                  value={formData.personName}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>

            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Person Email <span style={{ color: "red" }}>*</span>
                </Text>
                <Input
                  height="40px"
                  placeholder="Enter Person Email"
                  name="personEmail"
                  value={formData.personEmail}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>

            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Person Phone
                </Text>
                <Input
                  height="40px"
                  placeholder="Enter Person Phone Number"
                  name="personPhoneNumber"
                  value={formData.personPhoneNumber}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>

            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Person Designation
                </Text>
                <Input
                  height="40px"
                  placeholder="Enter Person Designation"
                  name="personDesignation"
                  value={formData.personDesignation}
                  onChange={handleChange}
                />
              </Box>
            </GridItem>

            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Booth Number
                </Text>
                <Select
                  mode="tags"
                  style={{
                    height: "40px",
                  }}
                  placeholder="Enter Product Type Tags"
                  onChange={(value) => {
                    setFormData({
                      ...formData,
                      exhibitorBoothNumberIdentifier: value,
                    });
                  }}
                  value={formData.exhibitorBoothNumberIdentifier}
                  options={[]}
                />
              </Box>
            </GridItem>

            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Product Type Tags 
                </Text>
                <Select
                  mode="tags"
                  style={{
                    height: "40px",
                  }}
                  placeholder="Enter Product Type Tags"
                  onChange={(value) => {
                    setFormData({
                      ...formData,
                      productTypeTags: value,
                    });
                  }}
                  value={formData.productTypeTags}
                  options={[]}
                />
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Text fontSize={"sm"} fontWeight={"500"}>
                  Subscription Plan <span style={{ color: "red" }}>*</span>
                </Text>
                <Select
                  // mode="tags"
                  style={{
                    height: "40px",
                  }}
                  placeholder="Choose subscription plan"
                  onChange={(value) => {
                    setFormData({
                      ...formData,
                      subscriptionId: value,
                    });
                  }}
                  value={formData.subscriptionId}
                  options={subscriptionsData}
                />
              </Box>
            </GridItem>
          </Grid>

          <Box mt={10}>
            <Flex justifyContent={"end"}>
              <Button
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                h={"40px"}
                background={"primary.main"}
                color={"white"}
                px={3}
                gap={2}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Flex>
          </Box>
        </Box>
      </Modal>
      <Flex justifyContent={"space-between"} mt={"10px"}>
        <Text size={"lg"} fontWeight={600}>
          Exhibitors
        </Text>
        <Flex gap={3}>
          <Input
            placeholder="Search by name"
            type="text"
            border={"1px solid"}
            outline={"none"}
            _focus={{ outline: "none", border: "1px solid" }}
            h={"35px"}
            w={"200px"}
            px={2}
            borderColor={"gray.300"}
          />
          <Button
            display={"flex"}
            onClick={() => {
              setEdit(false);
              clearData();
              setEditId("");
              showModal();
            }}
            justifyContent={"center"}
            alignItems={"center"}
            h={"100%"}
            background={"primary.main"}
            color={"white"}
            px={3}
            gap={2}
          >
            <AiOutlinePlus /> Exhibitor
          </Button>
          <Button
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            h={"100%"}
            background={"primary.main"}
            color={"white"}
            px={3}
            onClick={showBulkModal}
          >
            Import
          </Button>
        </Flex>
      </Flex>

      <Box
        my={"50px"}
        width={"30%"}
        display={"flex"}
        flexDirection={"column"}
        gap={"10px"}
      >
        <Text fontSize={"sm"} fontWeight={"500"}>
          Select Event
        </Text>
        <Select
          style={{
            height: "40px",
          }}
          placeholder="Select Event"
          onChange={(value) => {
            setSortEventId(value);
          }}
          options={[{ label: "All", value: "" }, ...eventData]}
          value={sortEventId}
        />
      </Box>

      <Box>
        {total === 0 ? (
          <Text>No Exhibitors Found</Text>
        ) : (
          <Text>Total Exhibitors: {total}</Text>
        )}
      </Box>

      <Box mt={5}>
        <Flex justifyContent={"start"} mb={1} gap={1}>
          <Button
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            h={"100%"}
            background={"button.green"}
            color={"white"}
            px={3}
            py={1}
            onClick={() => {
              resetPasswordClick(selectedRows);
            }}
          >
            Reset Password
          </Button>
          <Button
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            h={"100%"}
            background={"secondary.light"}
            color={"secondary.main"}
            px={3}
            py={1}
            onClick={() => {
              if (selectedRows.length > 0) {
                showDeleteModal();
              } else {
                error("Please select atleast one entry to delete !");
              }

              // setDeleteId(object.exhibitorId);
            }}
          >
            Delete
          </Button>
        </Flex>
        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          dataSource={data}
          bordered
          columns={columns}
          scroll={{ x: true }}
          pagination={false}
          rowKey={generateRowKey}
        />
        <Flex justifyContent={"end"}>
          <Pagination
            current={currentPage}
            total={total}
            onChange={(number) => setCurrentPage(number)}
            pageSize={PAGESIZE}
          />
        </Flex>
      </Box>
    </div>
  );
};

export default Exhibitors;
