import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import AdminLayout from "../Components/Layouts/AdminLayout/AdminLayout";
import Home from "../Pages/Home";

// Admin Pages
import AdminDashboard from "../Pages/Admin/Dashboard";
import AdminEvents from "../Pages/Admin/Events";
import AdminOrganiser from "../Pages/Admin/Organiser";
import AdminSubscriptions from "../Pages/Admin/Subscriptions";
import AdminExhibitors from "../Pages/Admin/Exhibitor";
import AdminVisitors from "../Pages/Admin/Visitor";
import AdminVisitorRewards from "../Pages/Admin/VisitorRewards";
import AdminLogin from "../Pages/Admin/Login";

// Common
import MobileLayout from "../Components/Layouts/MobileLayout/MobileLayout";
import Login from "../Pages/Common/Login";
import ResetPassword from "../Pages/Common/ResetPassword";
import Relevance from "../Pages/Common/Relevance";
import Notifications from "../Components/Common/NoNotifications";
import Profile from "../Pages/Common/Profile";
import ForgotPassword from "../Pages/Common/ForgotPassword";
import CreateMeeting from "../Pages/Common/CreateMeeting";
import Meeting from "../Pages/Common/Meeting";

// Organizer
import Account from "../Pages/Organizer/Account";
import OrganizerLayout from "../Components/Layouts/OrganizerLayout/OrganizerLayout";
import OrganizerHome from "../Pages/Organizer/Home";
import Company from "../Pages/Organizer/Company";
import Chat from "../Pages/Organizer/Chat";
import Notification from "../Pages/Common/Notification";

//Exhibitor
import ExhibitorLayout from "../Components/Layouts/ExhibitorLayout/ExhibitorLayout";
import ExhibitorChat from "../Pages/Common/Chat";
import ExhibitorHome from "../Pages/Exhibitor/Home";
import ExhibitorCompany from "../Pages/Exhibitor/Company";
import ExhibitorAccount from "../Pages/Exhibitor/Account";
import ExhibitorProductAndServices from "../Pages/Exhibitor/ProductsAndServices";
import ExhibitorAnnouncements from "../Pages/Exhibitor/Announcements";
import ExhibitorProfile from "../Pages/Exhibitor/Profile";
import ExhibitorDashboard from "../Pages/Exhibitor/DashboardV2";

//Visitor
import VisitorLayout from "../Components/Layouts/VisitorLayout/VisitorLayout";
import VisitorChat from "../Pages/Common/Chat";
import VisitorHome from "../Pages/Visitor/Home2";
import VisitorCompany from "../Pages/Visitor/Company";
import VisitorAccount from "../Pages/Visitor/Account";
import VisitorRFP from "../Pages/Visitor/RFP";
import Qrscan from "../Pages/Visitor/Qrscan";
import VisitorProfile from "../Pages/Visitor/Profile";
import VisitorDashboard from "../Pages/Visitor/DashboardV2";

import { useDispatch, useSelector } from "react-redux";
import { AuthAPI } from "../Apis/authAPI";
import actions from "../redux/Actions";
import { errorHandler } from "../Utils/handler";
import { useToastWrapper } from "../Wrapper/toastWrapper";
import MeetingList from "../Pages/Common/MeetingList";
import Rewards from "../Pages/Visitor/Rewards";
import RewardsStatement from "../Pages/Visitor/RewardsStatement";
import RewardsRedeem from "../Pages/Visitor/RewardsRedeem";
import Subscription from "../Pages/Exhibitor/Subscription";
import SubscriptionPurchase from "../Pages/Exhibitor/SubscriptionPurchase";
import SubscriptionStatement from "../Pages/Exhibitor/SubscriptionStatement";
import SubscriptionCart from "../Pages/Exhibitor/SubscriptionCart";
import Calendar from "../Pages/Exhibitor/Calendar";
import CreatePassword from "../Pages/Common/CreatePassword";
import RewardsRedemption from "../Pages/Visitor/RewardsRedemption";

type Props = {};

const Router = (props: Props) => {
  const user = useSelector((state: any) => state.user);

  const { error } = useToastWrapper();

  const dispatch = useDispatch();

  const refreshToken = async (token: string) => {
    try {
      const res = await AuthAPI.patchRefreshToken(token);
      if (res.success) {
        console.log(res);
        dispatch(actions.user.changeToken({ token: res.data.accessToken }));
      }
    } catch (err: any) {
      console.log(err);
      const errMsg = errorHandler(err);
      error(errMsg);
    }
  };

  useEffect(() => {
    if (user && user?.token) {
      refreshToken(user?.token);
    }
  }, [user]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />

      {/* Admin Pages */}

      <Route
        path="/admin"
        element={<AdminLayout children={<AdminDashboard />} />}
      />
      <Route path="/admin/login" element={<AdminLogin />} />
      <Route
        path="/admin/organizer"
        element={<AdminLayout children={<AdminOrganiser />} />}
      />
      {/* <Route
        path="/admin/subscriptions"
        element={<AdminLayout children={<AdminSubscriptions />} />}
      /> */}
      <Route
        path="/admin/events"
        element={<AdminLayout children={<AdminEvents />} />}
      />
      <Route
        path="/admin/exhibitors"
        element={<AdminLayout children={<AdminExhibitors />} />}
      />

      <Route
        path="/admin/visitors"
        element={<AdminLayout children={<AdminVisitors />} />}
      />

      <Route
        path="/admin/visitor-rewards"
        element={<AdminLayout children={<AdminVisitorRewards />} />}
      />

      {/* Common */}
      <Route path="/login" element={<MobileLayout children={<Login />} />} />
      <Route
        path="/forgot-password"
        element={<MobileLayout children={<ForgotPassword />} />}
      />
      <Route
        path="/create-password/:token"
        element={<MobileLayout children={<CreatePassword />} />}
      />
      <Route
        path="/reset-password"
        element={<MobileLayout children={<ResetPassword />} />}
      />
      <Route
        path="/relevance"
        element={<MobileLayout children={<Relevance />} />}
      />
      <Route
        path="/notification"
        element={<MobileLayout children={<Notifications />} />}
      />
      <Route
        path="/profile"
        element={<MobileLayout children={<Profile />} />}
      />
      <Route
        path="/meet"
        element={<MobileLayout children={<CreateMeeting />} />}
      />
      {/* <Route
        path="/meetings"
        element={<MobileLayout children={<MeetingList />} />}
      /> */}

      {/* ORGANIZER */}

      <Route
        path="/organizer"
        element={<OrganizerLayout children={<OrganizerHome />} />}
      />

      <Route
        path="/organizer/company"
        element={<OrganizerLayout children={<Company />} />}
      />

      <Route
        path="/organizer/chat"
        element={<OrganizerLayout children={<Chat />} />}
      />

      <Route
        path="/organizer/myaccount"
        element={<OrganizerLayout children={<Account />} />}
      />

      <Route
        path="/organizer/notifications"
        element={<OrganizerLayout children={<Notification />} />}
      />

      {/* Exhibitor */}
      <Route
        path="/exhibitor"
        element={<ExhibitorLayout children={<ExhibitorDashboard />} />}
      />
      <Route
        path="/exhibitor/list"
        element={<ExhibitorLayout children={<ExhibitorHome />} />}
      />
      <Route
        path="/exhibitor/calendar"
        element={<ExhibitorLayout children={<MeetingList />} />}
      />
      <Route
        path="/exhibitor/profile"
        element={<ExhibitorLayout children={<ExhibitorProfile />} />}
      />
      <Route
        path="/exhibitor/chat"
        element={<ExhibitorLayout children={<ExhibitorChat />} />}
      />
      <Route
        path="/exhibitor/notifications"
        element={<ExhibitorLayout children={<Notification />} />}
      />
      <Route
        path="/exhibitor/company"
        element={<ExhibitorLayout children={<ExhibitorCompany />} />}
      />
      <Route
        path="/exhibitor/myaccount"
        element={<ExhibitorLayout children={<ExhibitorAccount />} />}
      />
      <Route
        path="/exhibitor/pas"
        element={<ExhibitorLayout children={<ExhibitorProductAndServices />} />}
      />
      <Route
        path="/exhibitor/announcements"
        element={<ExhibitorLayout children={<ExhibitorAnnouncements />} />}
      />
      <Route
        path="/exhibitor/subscriptions"
        element={<ExhibitorLayout children={<Subscription />} />}
      />
      <Route
        path="/exhibitor/subscriptions-cart"
        element={<ExhibitorLayout children={<SubscriptionCart />} />}
      />
      <Route
        path="/exhibitor/subscriptions-purchase"
        element={<ExhibitorLayout children={<SubscriptionPurchase />} />}
      />
      <Route
        path="/exhibitor/subscriptions-statements"
        element={<ExhibitorLayout children={<SubscriptionStatement />} />}
      />

      {/* Visitor */}
      <Route
        path="/visitor"
        element={<VisitorLayout children={<VisitorDashboard />} />}
      />
      <Route
        path="/visitor/calendar"
        element={<VisitorLayout children={<MeetingList />} />}
      />
      <Route
        path="/visitor/list"
        element={<VisitorLayout children={<VisitorHome />} />}
      />
      <Route
        path="/visitor/profile"
        element={<VisitorLayout children={<VisitorProfile />} />}
      />
      <Route
        path="/visitor/chat"
        element={<VisitorLayout children={<VisitorChat />} />}
      />
      <Route
        path="/visitor/notifications"
        element={<VisitorLayout children={<Notification />} />}
      />
      <Route
        path="/visitor/company"
        element={<VisitorLayout children={<VisitorCompany />} />}
      />
      <Route
        path="/visitor/myaccount"
        element={<VisitorLayout children={<VisitorAccount />} />}
      />
      <Route
        path="/visitor/rfp"
        element={<VisitorLayout children={<VisitorRFP />} />}
      />
      <Route
        path="/visitor/scan"
        element={<VisitorLayout children={<Qrscan />} />}
      />
      <Route
        path="/visitor/rewards"
        element={<VisitorLayout children={<Rewards />} />}
      />
      <Route
        path="/visitor/rewards-statement"
        element={<VisitorLayout children={<RewardsStatement />} />}
      />
      <Route
        path="/visitor/rewards-redeem"
        element={<VisitorLayout children={<RewardsRedeem />} />}
      />
      <Route
        path="/visitor/rewards-redemptiom/:redemptionId"
        element={<VisitorLayout children={<RewardsRedemption />} />}
      />
    </Routes>
  );
};

export default Router;
